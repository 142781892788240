<div class="row animated fadeIn fast">


    <!-- Datos de la cabecera de la entrada-->
    <div class="col-md-6 animated fadeIn fast">
        <app-entrada-info [entrada]="entrada"></app-entrada-info>
    </div>


    <!-- Medidas del palé-->
    <div class="col-md-6 col-xs-12  animated fadeIn fast">
        <div class="card border border-info rounded">
            <div class="card-body">

                <form class="form p-t-20"
                      [formGroup]="medidasForm"
                      (ngSubmit)="enviarMedidas()"
                      class="form-horizontal">

                    <h4 class="card-title text-info">Pesos y medidas palé {{entrada_pale}}</h4>
                    <h6 class="card-subtitle">Medidas totales del palé</h6>

                    <div class="row">


                        <!-- largo -->
                        <div class="col-md-3">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': enviado && f['largo'].errors }">
                                <label class="form-control-label">Largo:</label>
                                <input formControlName="largo"
                                       type="number"
                                       step='1'
                                       formControlName="largo"
                                       class="form-control"
                                       [ngClass]="{ 'form-control-danger': enviado && f['largo'].errors }">
                                <small>cm</small>

                                <div *ngIf="enviado && f['largo'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['largo'].errors['numEntero']">Debe ser entero</div>
                                    <div *ngIf="f['largo'].errors['esCero']">No puede ser 0</div>
                                    <div *ngIf="f['largo'].errors?.['apiError']">{{medidasForm.get('largo')?.errors?.['apiError']}}</div>
                                </div>
                            </div>
                        </div>


                        <!-- ancho -->
                        <div class="col-md-3">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': enviado && f['ancho'].errors }">
                                <label class="form-control-label">Ancho:</label>
                                <input formControlName="ancho"
                                       type="number"
                                       step='1'
                                       formControlName="ancho"
                                       class="form-control"
                                       [ngClass]="{ 'form-control-danger': enviado && f['ancho'].errors }">
                                <small>cm</small>

                                <div *ngIf="enviado && f['ancho'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['ancho'].errors['numEntero']">Debe ser entero</div>
                                    <div *ngIf="f['ancho'].errors['esCero']">No puede ser 0</div>
                                    <div *ngIf="f['ancho'].errors?.['apiError']">{{medidasForm.get('ancho')?.errors?.['apiError']}}</div>
                                </div>
                            </div>
                        </div>


                        <!-- alto -->
                        <div class="col-md-3">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': enviado && f['alto'].errors }">
                                <label class="form-control-label">Alto:</label>
                                <input formControlName="alto"
                                       type="number"
                                       step='1'
                                       formControlName="alto"
                                       class="form-control"
                                       [ngClass]="{ 'form-control-danger': enviado && f['alto'].errors }">
                                <small>cm</small>

                                <div *ngIf="enviado && f['alto'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['alto'].errors['numEntero']">Debe ser entero</div>
                                    <div *ngIf="f['alto'].errors['esCero']">No puede ser 0</div>
                                    <div *ngIf="f['alto'].errors?.['apiError']">{{medidasForm.get('alto')?.errors?.['apiError']}}</div>
                                </div>
                            </div>
                        </div>


                        <!-- peso -->
                        <div class="col-md-3">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': enviado && f['peso'].errors }">
                                <label class="form-control-label">Peso:</label>
                                <input formControlName="peso"
                                       type="number"
                                       step='0.05'
                                       formControlName="peso"
                                       class="form-control"
                                       [ngClass]="{ 'form-control-danger': enviado && f['peso'].errors }">
                                <small>kg</small>

                                <div *ngIf="enviado && f['peso'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['peso'].errors['numEntero']">Debe ser entero</div>
                                    <div *ngIf="f['peso'].errors?.['apiError']">{{medidasForm.get('peso')?.errors?.['apiError']}}</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <button [disabled]="loading"
                            type="submit"
                            class="btn waves-effect waves-light btn-rounded btn-primary">
                        <i *ngIf="loading"
                           class="fa fa-spinner fa-spin"></i>
                        Buscar ubicaciones</button>

                </form>

            </div>
        </div>
    </div>



    <!-- Pestañas -->
    <div class="col-md-6 col-xs-12  animated fadeIn fast">
        <div class="card border border-info rounded">

            <div>
                <ul class="nav nav-tabs customtab"
                    role="tablist">

                    <li class="nav-item">
                        <a #tabUbi1Link
                           class="nav-link active"
                           data-toggle="tab"
                           href="#tabUbi1"
                           role="tab">
                            <span class="hidden-sm-up"><i class="ti-home"></i></span>
                            <span class="hidden-xs-down">
                                <span class="card-subtitle text-info">Ubicaciones libres para esas medidas</span>
                            </span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link"
                           data-toggle="tab"
                           href="#tabUbi2"
                           role="tab">
                            <span class="hidden-sm-up"><i class="ti-user"></i></span>
                            <span class="hidden-xs-down">
                                <span class="card-subtitle text-info">Ubicaciones reservadas a este cliente</span>
                            </span>
                        </a>
                    </li>

                </ul>
            </div>

            <!-- Tab panes -->
            <div class="tab-content">

                <div class="tab-pane active"
                     id="tabUbi1"
                     role="tabpanel"
                     aria-expanded="false">

                    <!-- filtro por alturas -->
                    <div *ngIf="ubiAlturas.length > 0"
                         class="card-body"
                         style="padding: 10px 10px 0 0;">
                        <div class="form-group row"
                             style="margin-top: 1px; margin-left: 22px; margin-bottom: 0;">
                            <div class="col">
                                <label class=" form-control-label text-small text-muted card-subtitle "
                                       style="display: inline-block; margin-right: 10px;">Filtrar por alturas:</label>
                                <select class="form-control form-control-sm"
                                        [(ngModel)]="selectedAltura"
                                        (ngModelChange)="onSelectUbiAltura()"
                                        style="display: inline-block; width: auto;">
                                    <option value=''>--</option>
                                    <option *ngFor="let item of ubiAlturas"
                                            [value]="item"
                                            style="font-family: 'Verdana', monospace; letter-spacing: 0.6px;">{{item}}</option>
                                </select>
                            </div>
                        </div>
                    </div>


                    <!-- Lista de ubicaciones libres para esas medidas de palé-->

                    <div class="card">
                        <div class="card-body"
                             style="padding-top: 0;">

                            <span *ngIf="!ubiList.length">
                                <h4 class="card-title text-info"> </h4>
                                <h6 class="card-subtitle m-3">Entra medidas totales para buscar ubicaciones</h6>
                            </span>

                            <div *ngIf="ubiList.length">
                                <ngx-datatable class='custom pointer'
                                               [rows]="filteredUbiList"
                                               [limit]="10"
                                               [loadingIndicator]="loading"
                                               [columnMode]="'force'"
                                               [headerHeight]="50"
                                               [footerHeight]="50"
                                               [rowHeight]="'auto'"
                                               [messages]="messages"
                                               [reorderable]="true"
                                               [selectionType]="SelectionType.single"
                                               (select)="onRowUbiClick($event)">

                                    <!-- codigo -->
                                    <ngx-datatable-column prop='codigo'
                                                          name="Propuestas">
                                        <ng-template let-value="value"
                                                     ngx-datatable-cell-template>
                                            <div style="font-family: 'Verdana', monospace; letter-spacing: 0.6px;">
                                                {{value | mask: '00.00.00.00.00.00'}}
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <!-- tipo.nombre -->
                                    <ngx-datatable-column prop="tipo.nombre"
                                                          name="Tipo">
                                    </ngx-datatable-column>

                                </ngx-datatable>

                            </div>
                        </div>
                    </div>

                </div>

                <div class="tab-pane"
                     id="tabUbi2"
                     role="tabpanel"
                     aria-expanded="false">
                    <!-- Lista de ubicaciones del cliente -->
                    <app-ubicaciones-cliente [padre]="this"
                                             [entrada]="entrada"> </app-ubicaciones-cliente>
                </div>

            </div>
        </div>
    </div>





    <!-- Formulario de ubucación -->
    <div class="col-md-6 col-xs-12  animated fadeIn fast">

        <form class="form p-t-20"
              [formGroup]="ubiForm"
              (ngSubmit)="enviarUbi()"
              class="form-horizontal ">

            <div class="card border border-success rounded">

                <div class="card-body">
                    <h4 class="card-title text-info">Ubicación</h4>
                    <h6 class="card-subtitle"> </h6>

                    <!-- ubicacion -->
                    <div class="col-md-8">
                        <div class="form-group"
                             [ngClass]="{ 'has-danger': enviadoU && fu['ubicacion'].errors }">
                            <label class="form-control-label">Ubicar palé en:</label>
                            <input type="text"
                                   formControlName="ubicacion"
                                   (input)="cambiaUbi()"
                                   mask="00.00.00.00.00.00"
                                   class="form-control grande"
                                   style="font-family: 'Verdana', monospace; letter-spacing: 0.6px;"
                                   [ngClass]="{ 'form-control-danger': enviadoU && fu['ubicacion'].errors }">

                            <div *ngIf="enviadoU && fu['ubicacion'].errors"
                                 class="form-control-feedback">
                                <div *ngIf="fu['ubicacion'].errors['required']">Obligatorio</div>
                                <div *ngIf="fu['ubicacion'].errors['minlength']">Deben ser 12 digitos</div>
                                <div *ngIf="fu['ubicacion'].errors['maxlength']">Deben ser 12 digitos</div>
                                <div *ngIf="fu['ubicacion'].errors['empiezaPor']">No se corresponde con el almacén de entrada. Debe empezar por {{entrada.almCodigo}}</div>
                                <div *ngIf="fu['ubicacion'].errors?.['apiError']">{{ubiForm.get('ubicacion')?.errors?.['apiError']}}</div>
                            </div>
                            <small>{{codUbiDecript}}</small><br>
                            <small class="text-secondary">{{infoAlmacenObj.nombre }}</small>
                        </div>
                    </div>


                </div>

                <!-- 
                                    <pre>
                                    {{fu['ubicacion'].errors |  json }} 
                                </pre>
                             -->



                <div class="card-footer">

                    <div class="d-flex">
                        <button [disabled]="loading"
                                type="button"
                                class="btn waves-effect waves-light btn-rounded btn-secondary"
                                [routerLink]="'/panel/entradas_lista'">Cancelar</button>

                        <button [disabled]="loading"
                                type="submit"
                                class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                            <i *ngIf="loading"
                               class="fa fa-spinner fa-spin"></i>
                            Enviar</button>
                    </div>

                </div><!-- card footer -->

            </div>
        </form>

    </div>






    <!-- Detall del palé-->
    <div class="col-12 animated fadeIn fast">
        <div class="card border border-info rounded">

            <div class="card-body">
                <h4 class="card-title text-info">Palé {{entrada_pale}}</h4>
                <h6 class="card-subtitle">Contenido del palé</h6>
                <div class="bootstrap-table">

                    <div class="table-responsive">
                        <table class="table table-sm table-striped table-bordered table-hover">

                            <thead>
                                <tr>
                                    <th>Sku</th>
                                    <th>Artículo</th>
                                    <th>Lote</th>
                                    <th style="padding-right: 45px; text-align: right;">cantidad</th>
                                </tr>
                            </thead>

                            <!-- Si hay detall, mostrarlo -->
                            <tbody *ngIf="entrada.detall">
                                <ng-container *ngFor="let lin of entrada.detall; let i = index">
                                    <tr>
                                        <td>{{ lin.artSku }}</td>
                                        <td>{{ lin.artDescripcion }}</td>
                                        <td>{{ lin.lote }}</td>
                                        <td style="padding-right: 45px; text-align: right;">
                                            <div class="monospace">{{lin.cantidad | number: '0.0-0':'es'}}</div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>

                        </table>
                    </div>



                </div>
            </div>

            <div class="clearfix"></div>
        </div>

    </div>







</div>