<div *ngIf="loading"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Cargando...</h3>
</div>


<div class="row ">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body animated fadeIn fast">


                <!-- cabecera  -->
                <div class="row mb-2">

                    <div class="col-4">
                        <div class="input-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   id="buscaTodo"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   [(ngModel)]="strBuscarTodo"
                                   (keyup)="buscarTodo()" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary"
                                        type="button"
                                        title="Limpiar búsqueda"
                                        (click)="limpiaBusca()">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>




                    <!-- cliSeleccionado -->
                    <div class="col-2">
                        <div class="input-group border border-dark rounded">
                            <div class="input-group-addon"
                                 title="Cleinte">
                                <i class="fa-solid fa-user"></i>
                            </div>
                            <select class="form-control"
                                    [(ngModel)]="cliSeleccionado"
                                    (ngModelChange)="onClienteSelect($event)">
                                <option value=''>Todos los clientes</option>
                                <option *ngFor="let cli of clientesList"
                                        [value]=cli.id>
                                    {{cli.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>



                    <!-- estadoPedidoSeleccionado -->
                    <div class="col-2">
                        <div class="input-group border border-dark rounded">
                            <div class="input-group-addon"
                                 title="Estado del pedido">
                                <i class="fa-solid fa-chevron-right"></i>
                            </div>
                            <select class="form-control"
                                    [(ngModel)]="estadoPedidoSeleccionado"
                                    (ngModelChange)="onEstadoPedidoSelect($event)">
                                <option value=''>Todos los estados</option>
                                <option *ngFor="let estado of pedidosEstadosList"
                                        [value]=estado.valor>
                                    {{estado.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>


                    <!-- servicioSeleccionado -->
                    <div class="col-2">
                        <div class="input-group border border-dark rounded">
                            <div class="input-group-addon"
                                 title="Servicio de envío del pedido"
                                 style="cursor: default">
                                <span>S</span>
                            </div>
                            <select class="form-control"
                                    [(ngModel)]="cod_servicioSeleccionado"
                                    (ngModelChange)="onServicioSelect($event)">
                                <option value=''>Todos los servicios</option>
                                <option *ngFor="let item of serviciosEnvioList"
                                        [value]=item.codServicio
                                        [ngClass]="{ 'highlight-inte': item.codServicio === 'inte' }">
                                    {{item.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>



                    <div class="col-2">
                        <form>

                            <div class="row float-right">
                                <div class="col-sm-8">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="5">5</option>
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="999999">Todo</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group float-right">
                                        <div class="btn-group">

                                            <button type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>

                </div>



                <div class="rounded border">
                    <!-- ngx-datatable -->
                    <ngx-datatable class="bootstrap"
                                   [style.width]="'100%'"
                                   [limit]="rowsLimit"
                                   [rows]="rows"
                                   [columnMode]="ColumnMode.force"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   [messages]="messages"
                                   rowHeight="auto"
                                   [summaryRow]="true"
                                   [summaryHeight]="50"
                                   [summaryPosition]="summaryPosition">


                        <!-- id -->
                        <ngx-datatable-column name="id"
                                              prop='id'
                                              [width]="75"
                                              [maxWidth]="90"
                                              [resizeable]="false"
                                              [cellClass]="'text-right'">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <div class="header"
                                     (click)="sort()"
                                     style="margin-top: 33px;">
                                    <h6 class="pointer link">Id</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- pedido_cli -->
                        <ngx-datatable-column name="pedido_cli"
                                              prop='pedido_cli'
                                              [flexGrow]="1">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'pedido_cli')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Pedido</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [class.font-weight-bold]="row.destacarLinea"
                                     [class.text-primary]="row.destacarLinea"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- cajetin -->
                        <ngx-datatable-column name="cajetin"
                                              prop='cajetin'
                                              [width]="75"
                                              [maxWidth]="90"
                                              [resizeable]="false"
                                              [cellClass]="'text-right'">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <div class="header"
                                     (click)="sort()"
                                     style="margin-top: 33px;">
                                    <h6 class="pointer link">Cajetín</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- camp_cod -->
                        <ngx-datatable-column name="camp_cod"
                                              prop='camp_cod'
                                              [width]="80"
                                              [resizeable]="false">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'camp_cod')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Campaña</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div class="d-flex align-items-center">
                                    <span class="monospace"
                                          [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- cliNombre -->
                        <ngx-datatable-column name="cliNombre"
                                              [flexGrow]="2">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'cliNombre')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Cliente</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- estado -->
                        <ngx-datatable-column name="estado"
                                              prop='estado'
                                              [flexGrow]="2"
                                              [maxWidth]="250">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <select class="form-control form-control-sm"
                                        (change)="buscaEstado($event.target )">
                                    <option value="">Todos</option>
                                    <option *ngFor="let estado of pedidosEstadosList"
                                            [value]="estado.valor">{{ estado.nombre }}</option>
                                </select><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Estado</h6>
                                </div>
                            </ng-template>

                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(  row.estado_at, textoDestacado) + ' ' + (value | pedidoEstado)"></div>
                            </ng-template>

                        </ngx-datatable-column>




                        <!-- contrarrembolso -->
                        <ngx-datatable-column name="contrarrembolso"
                                              prop='contrarrembolso'
                                              [flexGrow]="1"
                                              [maxWidth]="110"
                                              [cellClass]="'text-right'">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <div class="header"
                                     (click)="sort()"
                                     style="margin-top: 33px;">
                                    <h6 class="pointer link">Reembolso</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                {{ value | number: '0.2-2':'es' }} €
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- tot_referencias -->
                        <ngx-datatable-column name="tot_referencias"
                                              prop='tot_referencias'
                                              [flexGrow]="1"
                                              [maxWidth]="110"
                                              [cellClass]="'text-right'">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <div class="header"
                                     (click)="sort()"
                                     style="margin-top: 33px;">
                                    <h6 class="pointer link">Referencias</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                {{ value | number: '1.0-0':'es' }}
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- tot_articulos -->
                        <ngx-datatable-column name="tot_articulos"
                                              prop='tot_articulos'
                                              [flexGrow]="1"
                                              [maxWidth]="110"
                                              [cellClass]="'text-right'">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <div class="header"
                                     (click)="sort()"
                                     style="margin-top: 33px;">
                                    <h6 class="pointer link">Artículos</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                {{ value | number: '1.0-0':'es' }}
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- cod_servicio -->
                        <ngx-datatable-column name="cod_servicio"
                                              prop='cod_servicio'
                                              [flexGrow]="1"
                                              [maxWidth]="250">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <select class="form-control form-control-sm"
                                        (change)="buscaServicio($event.target)">
                                    <option value="">Todos</option>
                                    <option *ngFor="let servicio of serviciosEnvioList"
                                            [value]="servicio.codServicio"
                                            [ngClass]="{ 'highlight-inte': servicio.codServicio === 'inte' }">
                                        {{ servicio.nombre }}
                                    </option>
                                </select><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Servicio</h6>
                                </div>
                            </ng-template>

                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [ngClass]="{ 'text-warning font-weight-bold' : row.cod_servicio === 'inte' }"
                                     [innerHTML]="resaltarTexto(  row.cod_servicio, textoDestacado)  "></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- dirección -->
                        <ngx-datatable-column name="direccion"
                                              [flexGrow]="3">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'direccion')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Enviar a</h6>
                                </div>
                            </ng-template>
                            <ng-template let-row="row"
                                         let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto( (row.cp + ' ' + row.poblacion + ' ' + row.pais_iso), textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>





                        <!-- Acciones -->
                        <ngx-datatable-column name="Acciones"
                                              [width]="223"
                                              [maxWidth]="223"
                                              [sortable]="false"
                                              [resizeable]="false">

                            <ng-template let-row="row"
                                         let-value="value"
                                         ngx-datatable-cell-template>

                                <a (click)="infoClickPedido(row)"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="INFORMACIÓN de este pedido">
                                    <span class="badge"
                                          [ngClass]="{'infoSelect': row.id == pedidoSelected.id}">
                                        I</span>
                                </a>

                                <a (click)="(row.estado === '3' || row.estado === '5' ) ? descargaAlbaranClick(row.id!) : null"
                                   class="link mr-2 pointer"
                                   title="Descargar ALBARÁN"
                                   [ngClass]="{'disabled':( row.estado !== '3' && row.estado !== '5') }">
                                    <span class="badge">A</span>
                                </a>

                                <a (click)="(row.estado === '3' || row.estado === '5' ) ? openTracking(row.id!) : null"
                                   class="link mr-2 pointer"
                                   title="TRACKING del pedido"
                                   [ngClass]="{'disabled':( row.estado !== '3' && row.estado !== '5') }">
                                    <span class="badge">T</span>
                                </a>

                                <a (click)="['1', '8'].includes(row.estado) ? abrirModalCabeza(row) : null"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="MODIFICAR la CABECERA de este pedido"
                                   [ngClass]="{'disabled': !(['1', '8'].includes(row.estado))}">
                                    <span class="badge">M</span>
                                </a>

                                <a (click)="(row.cod_servicio === 'inte') ? pdfEtiquetaPedido(row) : null"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="Imprmir ETIQUETA de este pedido"
                                   [ngClass]="{'disabled': !(row.cod_servicio === 'inte')}">
                                    <span class="badge">L</span>
                                </a>

                                <a (click)="(row.estado === Constantes.ESTADO_PEDIDO_SERVIDO || row.estado === Constantes.ESTADO_PEDIDO_SERVIDO_PARCIALMENTE) ? generaXlsEnvio(row) : null"
                                   class="link mr-2 pointer"
                                   title="Exportar este pedido a DTX"
                                   [ngClass]="{'disabled':( row.estado !== Constantes.ESTADO_PEDIDO_SERVIDO  && row.estado !== Constantes.ESTADO_PEDIDO_SERVIDO_PARCIALMENTE) }">
                                    <span class="badge">D</span>
                                </a>

                            </ng-template>

                        </ngx-datatable-column>

                    </ngx-datatable>
                </div>

            </div>
        </div>
    </div>



    <!-- Tabla de detalle del pedido seleccionado -->
    <div *ngIf="pedidoSelected?.detall"
         class="col-12">

        <div class="card">

            <div class="card-body">
                <h4 class="card-title">Detalle del pedido</h4>
                <div class="text-info">
                    <span class="font-weight-bold"> {{pedidoSelected.pedido_cli}} </span>
                    <span [innerHTML]=" pedidoSelected.estado | pedidoEstado "> </span>
                    <span class="font-italic text-muted text-sm"> {{ pedidoSelected.estado_at }}</span>
                </div>


                <h6 class="card-subtitle">{{pedidoSelected.nombre}}
                    <span *ngIf="pedidoSelected.contrarrembolso && pedidoSelected.contrarrembolso > 0"
                          class="text-danger font-italic text-sm">
                        (contrarrembolso de {{ pedidoSelected.contrarrembolso | number: '0.0-0':'es'}} €)
                    </span>
                </h6>

                <h6 class="card-subtitle"> {{pedidoSelected.cp}} - {{pedidoSelected.poblacion}}</h6>


                <div class="table-responsive">
                    <table class="table table-sm table-striped table-bordered table-hover">

                        <thead>
                            <tr>
                                <th></th>
                                <th>SKU</th>
                                <th>Ean</th>
                                <th>Descripción</th>
                                <th style="text-align: center; padding-left: 10px; white-space: nowrap; width: 1px;">Cantidad</th>
                                <th style="text-align: center; padding-left: 10px; white-space: nowrap; width: 1px;">A Repartir</th>
                                <th style="text-align: center; padding-left: 10px; white-space: nowrap; width: 1px;">Repartido</th>
                            </tr>
                        </thead>


                        <!-- Si hay pedidoSelected, mostrar el detall -->

                        <tbody>
                            <ng-container *ngFor="let detall of pedidoSelected.detall; let i = index">

                                <tr>
                                    <td>
                                        <img [src]="fotoDir +'/articulos' + imgResized +  detall.artFoto"
                                             loading="lazy"
                                             alt="Foto del artículo"
                                             class="rounded border"
                                             height="25px">
                                    </td>
                                    <td>{{ detall.artSku }}</td>
                                    <td>{{ detall.artEan }}</td>
                                    <td>{{ detall.artDescripcion }}</td>


                                    <td style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 mr-20 monospace">
                                            {{ detall.cantidad | number: '0.0-0':'es'}}
                                        </div>
                                    </td>
                                    <td style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 mr-20 monospace"
                                             [ngClass]="{'text-danger': (detall.cantidad != detall.cant_repartida)}">
                                            {{ detall.cant_repartir | number: '0.0-0':'es'}}
                                        </div>
                                    </td>
                                    <td style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 pr-2 mr-20 monospace"
                                             [ngClass]="{
                                            'bg-green rounded': (detall.cant_repartir == detall.cant_repartida) && detall.cant_repartida! > 0,
                                            'bg-blue rounded': (detall.cant_repartida! < detall.cant_repartir!) && detall.cant_repartida! > 0
                                         }">
                                            {{ detall.cant_repartida | number: '0.0-0':'es'}}
                                        </div>
                                    </td>


                                </tr>

                            </ng-container>
                        </tbody>


                        <tfoot>
                            <tr>
                                <td colspan="2"></td>
                                <td style="text-align: right; padding-left: 10px">
                                    <div class="pl-25 mr-20 monospace">
                                        {{ pedidoSelected.tot_referencias | number: '0.0-0':'es' }}
                                    </div>
                                </td>

                                <td></td>
                                <td style="text-align: right; padding-left: 10px">
                                    <div class="pl-25 mr-20 monospace">
                                        {{ pedidoSelected.tot_articulos | number: '0.0-0':'es' }}
                                    </div>
                                </td>
                                <td style="text-align: right; padding-left: 10px">
                                    <div class="pl-25 mr-20 monospace">
                                        {{ pedidoSelected.tot_cant_repartir | number: '0.0-0':'es' }}
                                    </div>
                                </td>
                                <td style="text-align: right; padding-left: 10px">
                                    <div class="pl-25 mr-20 monospace pr-2">
                                        {{ pedidoSelected.tot_cant_repartida | number: '0.0-0':'es' }}
                                    </div>
                                </td>
                            </tr>
                        </tfoot>


                    </table>
                </div>



            </div>
        </div>

        <div class="clearfix"></div>
    </div>

</div>

<!-- modal para edición de la cabecera del pedido -->
<app-modal-pedido-cabecera></app-modal-pedido-cabecera>