import * as Const from '../../../shared/constants';

// Componentes 
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// Interfaces / Modelos
import { ICliente } from 'src/app/interfaces/cliente';
import { IArticulo } from 'src/app/interfaces/articulo';

// Servicios
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { ArticulosService } from 'src/app/services/datos/articulos.service';
import { ModalImagenService } from 'src/app/services/modal-imagen.service';
import { PedidosExternosService } from 'src/app/services/datos/pedidosExternos.service';

// Librerías y entorno
import CustomVal from 'src/app/providers/CustomValidators';
import { environment } from 'src/environments/environment';
import { Subscription, delay } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-articulo-edit',
  templateUrl: './articulo-edit.component.html',
  styleUrls: ['./articulo-edit.component.scss']
})
export class ArticuloEditComponent implements OnInit {

  // Usar constantes en el template
  Const = Const

  articulo: IArticulo = {}
  paramId: string = '';

  public fotoDir: string = environment.fotoDir;
  public imgSubs: Subscription | undefined;

  clientesList: ICliente[] = [];

  public artForm: FormGroup = new FormGroup({});

  public enviado: boolean = false;
  public loading: boolean = false;

  constructor(
    private articulosSrv: ArticulosService,
    private pedidosExtSrv: PedidosExternosService,
    public usuarioSrv: UsuarioService,
    public clientesSrv: ClientesService,
    private fb: FormBuilder,
    private notificacionesSrv: NotificacionesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public modalImagenSrv: ModalImagenService
  ) { }

  ngOnInit(): void {

    this.artForm = this.fb.group({
      id_cliente: ['', Validators.required],
      ean: ['', Validators.required],
      sku: ['', Validators.required],
      descripcion: ['', [Validators.minLength(4), Validators.maxLength(150)]],
      url: ['', CustomVal.urlValidator],
      cantidad: [''],
      unidadesCaja: [''],
      largo: [''],
      ancho: [''],
      alto: [''],
      peso: [''],
      swLote: [false],
      swDefectuoso: [Const.NO_DEFECTUOSO],
      swActivo: [true],
      origen: [''],
    })

    // Suscribirse a los parámetros de la url, si envían una id, cargar los datos del articulo
    this.activatedRoute.params.subscribe(({ id }) => {
      this.articulo.foto = '_noFoto.png';
      this.paramId = id;

      // Es un artículo existente 
      if (/^\d/.test(this.paramId)) {
        // El valor de this.paramId comienza con un número, es un artículo existente 
        this.cargarArticulo(id);
      }

      // Es un artículo que hay que importar desde un pedido_externo_detall
      if (this.paramId.startsWith('E')) {
        this.paramId = this.paramId.slice(1);
        this.cargarArticuloExterno();
      }
    })


    // Valores por defecto para registros nuevos
    if (this.paramId === 'new') {
      this.articulo.artStock = 0;
      this.artForm.patchValue({
        swActivo: '1',
        origen: '2',
      });
    }


    this.loading = true;
    this.clientesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.clientesList = respuesta.data;
      })


    // Nos suscribimos a la imagen, 
    // Si cambia la imagen, actualizar el artículo.foto

    this.imgSubs = this.modalImagenSrv.nuevaImagen
      .pipe(delay(100))
      .subscribe(img => {
        this.articulo.foto = img.guardado_como;
      });

  }


  cargarArticulo(id: number) {
    this.loading = true;
    this.articulosSrv.getArticulo(id)
      .subscribe(resp => {
        this.loading = false;

        console.log('__cargarArticulo', resp);

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          this.usuarioSrv.logOut();
        } else {
          this.articulo = resp.data;
          this.cargaForm();
        }
      })
  }


  /* Carga en el formulario los valores de un articulo */
  cargaForm() {
    this.artForm.patchValue({
      id_cliente: this.articulo.id_cliente,
      swLote: this.articulo.swLote,
      swDefectuoso: this.articulo.swDefectuoso,
      swActivo: this.articulo.swActivo,
      origen: this.articulo.origen,
      ean: this.articulo.ean,
      sku: this.articulo.sku,
      descripcion: this.articulo.descripcion,
      url: this.articulo.url,
      unidadesCaja: this.articulo.unidadesCaja,
      largo: this.articulo.largo,
      ancho: this.articulo.ancho,
      alto: this.articulo.alto,
      peso: this.articulo.peso,
    })
  }


  cargarArticuloExterno() {
    console.log('Añadir el pedido_externo_detall: ', this.paramId);

    this.loading = true;
    this.pedidosExtSrv.getPedidoEDetall(parseInt(this.paramId))
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          this.usuarioSrv.logOut();
        } else {
          this.articulo = resp.data;

          this.artForm.patchValue({
            id_cliente: this.articulo.id_cliente,
            ean: this.articulo.ean,
            sku: this.articulo.sku,
            descripcion: this.articulo.descripcion,
            foto: this.articulo.foto
          })

        }
      })

    this.paramId = 'new';
  }


  enviar() {

    console.log('__enviar()', this.artForm.value)

    if (this.paramId === 'new') {
      this.newRec();
    } else {
      this.updateRec();
    }
    return
  }


  newRec() {
    this.enviado = true;

    if (this.artForm.invalid) {
      console.log("ERRORES:", this.artForm);
      return;
    }

    // Todo ok, enviar al BackEnd
    this.loading = true;
    this.articulosSrv.newRec(this.artForm.value)
      .subscribe(resp => {
        this.loading = false;

        if (resp.error) {
          // this.notificacionesService.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.artForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', `Articulo ${this.artForm.get('descripcion')?.value} creado correctamente con el <b>id: ${resp.data.id}</b>`);
          this.router.navigateByUrl(`panel/articulos_lista`);
        }
      })
  }

  updateRec() {
    this.enviado = true;

    if (this.artForm.invalid) {
      console.log("ERRORES:", this.artForm);
      return;
    }
    // Todo ok, enviar al BackEnd
    const datos = {
      ...this.artForm.value,
      id: this.articulo.id
    }
    this.loading = true;
    this.articulosSrv.updateRec(datos)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          // this.notificacionesService.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.artForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', `Articulo ${this.artForm.get('descripcion')?.value} actualizado correctamente `);
          this.router.navigateByUrl(`panel/articulos_lista`);
        }
      })
  }



  archivarClick() {
    Swal.fire({
      title: '¿Mover a histórico?',
      icon: 'question',
      html: `El artículo dejará de estar disponible`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {

        this.loading = true;
        this.articulosSrv.archivar(this.articulo.id!)
          .subscribe(resp => {
            this.loading = false;
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
              console.log(resp.mensaje);
              return
            }
            this.notificacionesSrv.aviso('info', `Articulo ${this.articulo.ean} movido a histórico`);
            this.router.navigateByUrl(`panel/articulos_lista`);
          })
      }
    })
  }


  // Valida si existe EAN en el histórico

  existeEan() {
    // Comprueba si el EAN ya existe en articulos_historico
    const ean = this.f['ean'].value;
    if (!ean) return;

    const data = { id_cliente: this.f['id_cliente'].value, ean: ean };

    this.loading = true;
    this.articulosSrv.getHistEan(data)
      .subscribe(resp => {
        this.loading = false;

        console.log(`__existeEan() resp`, resp);

        if (resp.error) {
          const control = this.f['ean'];
          if (control) control.setErrors({ 'apiError': resp.mensaje });
          return
        }

        if (!resp.error && resp.data.id) {

          Swal.fire({
            title: 'Artículo archivado',
            icon: 'question',
            html: `Se ha encontrado este artículo en el archivo de historicos. ¿Quieres cargar sus datos?`,
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Si',
          }).then((result) => {
            if (result.isConfirmed) {
              this.articulo = resp.data;

              this.articulo.alto = parseInt(this.articulo.alto?.toString() || '0', 0);
              this.articulo.ancho = parseInt(this.articulo.ancho?.toString() || '0', 0);
              this.articulo.largo = parseInt(this.articulo.largo?.toString() || '0', 0);

              this.cargaForm();

              this.notificacionesSrv.aviso('info', `Datos del artículo ${ean} cargados`);
              return
            }
          })

        }
      })
  }





  //  AUX  //


  get f() {
    return this.artForm.controls;
  }

  abrirModal(articulo: IArticulo) {
    this.modalImagenSrv.abrirModal('articulos', articulo.id?.toString() || '', articulo.foto);
  }


  toUpper(fieldName: string) {
    const formControl = this.f[fieldName];

    if (formControl && formControl.value) {
      formControl.setValue(formControl.value.toUpperCase());
    }
  }
}
