<div class="row animated fadeIn fast">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">

                <form class="form p-t-20"
                      [formGroup]="cliForm"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">
                    <div class="form-body">
                        <div class="row">
                            <div class="col ml-2 mb-2">
                                <h4><b>{{ cliente.nombre }}</b></h4>
                                <span class="ml-2">{{ cliente.direccion }} ({{ cliente.cp }} - {{ cliente.poblacion }}) </span>
                            </div>
                        </div>
                        <hr>

                        <!-- Pestañas -->
                        <ul class="nav nav-tabs"
                            role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active"
                                   data-toggle="tab"
                                   href="#entradas"
                                   role="tab">Entradas</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link"
                                   data-toggle="tab"
                                   href="#salidas"
                                   role="tab">Salidas</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link"
                                   data-toggle="tab"
                                   href="#devoluciones"
                                   role="tab">Devoluciones</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link"
                                   data-toggle="tab"
                                   href="#seguro"
                                   role="tab">Seguro</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link"
                                   data-toggle="tab"
                                   href="#almacenaje"
                                   role="tab">Almacenaje</a>
                            </li>
                        </ul>

                        <!-- Contenido de las pestañas -->
                        <div class="tab-content">


                            <!-- Pestaña de Entradas -->
                            <div class="tab-pane active"
                                 id="entradas"
                                 role="tabpanel">
                                <div class="row mt-4">
                                    <div class="col-md-12"> <!-- ENTRADAS -->

                                        <div class="row">
                                            <div class="col-md-12 ">
                                                <h4 class="text-info">Entradas:</h4>
                                            </div>
                                        </div>

                                        <div class="card border border-info rounded">
                                            <div class="card-body">

                                                <div class="div">

                                                    <div class="row">


                                                        <div class="col-md-6 text-right">
                                                            <br>
                                                            <h6><i>Por líneas (referencias) - Por línea en albarán de entrada</i></h6>
                                                        </div>


                                                        <!-- fac_entrada_MinLin -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_entrada_MinLin'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title="Número Mínimo de líneas para cada entrada">
                                                                    Primeras Líneas:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_entrada_MinLin"
                                                                       class="form-control"
                                                                       mask="separator.0"
                                                                       suffix=" Líneas"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_entrada_MinLin'].errors }">
                                                                <div *ngIf="enviado && f['fac_entrada_MinLin'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_entrada_MinLin'].errors?.['apiError']">{{cliForm.get('fac_entrada_MinLin')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <!-- fac_entrada_MinPrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_entrada_MinPrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title=" Precio por las primeras líneas">
                                                                    Por primeras líneas €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_entrada_MinPrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_entrada_MinPrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_entrada_MinPrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_entrada_MinPrecio'].errors?.['apiError']">{{cliForm.get('fac_entrada_MinPrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_entrada_LinPrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_entrada_LinPrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title=" Precio de cada línea por encima del mínimo de líneas">
                                                                    Por línea adicional €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_entrada_LinPrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_entrada_LinPrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_entrada_LinPrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_entrada_LinPrecio'].errors?.['apiError']">{{cliForm.get('fac_entrada_LinPrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div> <!-- row -->




                                                    <div class="row">


                                                        <!-- fac_entrada_UnidPrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_entrada_UnidPrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title="  Precio por unidad de cada entrada:">
                                                                    Precio por unidad €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_entrada_UnidPrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_entrada_UnidPrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_entrada_UnidPrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_entrada_UnidPrecio'].errors?.['apiError']">{{cliForm.get('fac_entrada_UnidPrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_entrada_PalePrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_entrada_PalePrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title=" Precio por cada Descarga de palé">
                                                                    Precio por palé €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_entrada_PalePrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_entrada_PalePrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_entrada_PalePrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_entrada_PalePrecio'].errors?.['apiError']">{{cliForm.get('fac_entrada_PalePrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_entrada_gestLotePrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_entrada_gestLotePrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title=" Precio por cada linea de la entrada que gestiona lote">
                                                                    Precio por lote €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_entrada_gestLotePrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_entrada_gestLotePrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_entrada_gestLotePrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_entrada_gestLotePrecio'].errors?.['apiError']">{{cliForm.get('fac_entrada_gestLotePrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>





                                                        <!-- fac_entrada_PrecioHora -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_entrada_PrecioHora'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio hora €:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_entrada_PrecioHora"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_entrada_PrecioHora'].errors }">
                                                                <div *ngIf="enviado && f['fac_entrada_PrecioHora'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_entrada_PrecioHora'].errors?.['apiError']">{{cliForm.get('fac_entrada_PrecioHora')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <!-- fac_entrada_PrecioEtiqueta -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_entrada_PrecioEtiqueta'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio Etiquetado €:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_entrada_PrecioEtiqueta"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_entrada_PrecioEtiqueta'].errors }">
                                                                <div *ngIf="enviado && f['fac_entrada_PrecioEtiqueta'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_entrada_PrecioEtiqueta'].errors?.['apiError']">{{cliForm.get('fac_entrada_PrecioEtiqueta')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_entrada_PrecioFijo -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_entrada_PrecioFijo'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title="Precio fijo por cada entrada, independiente de los otros valores">
                                                                    Precio Fijo €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>


                                                                <input type="text"
                                                                       formControlName="fac_entrada_PrecioFijo"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_entrada_PrecioFijo'].errors }">
                                                                <div *ngIf="enviado && f['fac_entrada_PrecioFijo'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_entrada_PrecioFijo'].errors?.['apiError']">{{cliForm.get('fac_entrada_PrecioFijo')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div> <!-- row -->



                                                    <div class="row">

                                                        <!-- fac_entrada_PrecioContainer20 -->
                                                        <div class="col-md-3">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_entrada_PrecioContainer20'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title="Precio por cada descarga de contenedor de 20 pies">
                                                                    Descarga Container 20 pies €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>


                                                                <input type="text"
                                                                       formControlName="fac_entrada_PrecioContainer20"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_entrada_PrecioContainer20'].errors }">
                                                                <div *ngIf="enviado && f['fac_entrada_PrecioContainer20'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_entrada_PrecioContainer20'].errors?.['apiError']">{{cliForm.get('fac_entrada_PrecioContainer20')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- fac_entrada_PrecioContainer40 -->
                                                        <div class="col-md-3">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_entrada_PrecioContainer40'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title="Precio por cada descarga de contenedor de 40 pies">
                                                                    Descarga Container 40 pies €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>


                                                                <input type="text"
                                                                       formControlName="fac_entrada_PrecioContainer40"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_entrada_PrecioContainer40'].errors }">
                                                                <div *ngIf="enviado && f['fac_entrada_PrecioContainer40'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_entrada_PrecioContainer40'].errors?.['apiError']">{{cliForm.get('fac_entrada_PrecioContainer40')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- SEPARADOR -->
                                                        <div class="col-md-2">
                                                        </div>

                                                        <!-- fac_entrada_LiteralEtiqueta -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_entrada_LiteralEtiqueta'].errors }">

                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title=" El literál que aparecerá en los formularios de entrada para el número de etiquetas">
                                                                    Literal en Etiquetado:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_entrada_LiteralEtiqueta"
                                                                       class="form-control"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_entrada_LiteralEtiqueta'].errors }">
                                                                <small><i>('Etiquetas')</i></small>
                                                                <div *ngIf="enviado && f['fac_entrada_LiteralEtiqueta'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_entrada_LiteralEtiqueta'].errors?.['apiError']">{{cliForm.get('fac_entrada_LiteralEtiqueta')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div> <!-- row -->



                                                </div>

                                            </div><!--card-body-->
                                        </div><!--card-->

                                    </div><!-- FIN ENTRADAS -->
                                </div>
                            </div> <!-- Pestaña de Entradas -->


                            <!-- Pestaña de Salidas -->
                            <div class="tab-pane"
                                 id="salidas"
                                 role="tabpanel">
                                <div class="row mt-4">
                                    <div class="col-md-12"> <!-- SALIDAS -->

                                        <div class="row">
                                            <div class="col-md-12 ">
                                                <h4 class="text-info">Salidas:</h4>
                                            </div>
                                        </div>

                                        <div class="card border border-info rounded">
                                            <div class="card-body">

                                                <div class="div">



                                                    <div class="row">


                                                        <div class="col-md-6 text-right">
                                                            <br>
                                                            <h6><i>Por líneas (referencias) - Por línea en albarán de salida</i></h6>
                                                        </div>


                                                        <!-- fac_salida_MinLin -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_salida_MinLin'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title="Número Mínimo de líneas para cada salida">
                                                                    Primeras Líneas:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_salida_MinLin"
                                                                       class="form-control"
                                                                       mask="separator.0"
                                                                       suffix=" Líneas"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_salida_MinLin'].errors }">
                                                                <div *ngIf="enviado && f['fac_salida_MinLin'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_salida_MinLin'].errors?.['apiError']">{{cliForm.get('fac_salida_MinLin')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <!-- fac_salida_MinPrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_salida_MinPrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title=" Precio por las primeras líneas">
                                                                    Por primeras líneas €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_salida_MinPrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_salida_MinPrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_salida_MinPrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_salida_MinPrecio'].errors?.['apiError']">{{cliForm.get('fac_salida_MinPrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>





                                                        <!-- fac_salida_LinPrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_salida_LinPrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title=" Precio de cada línea por encima del mínimo de líneas">
                                                                    Por línea adicional €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_salida_LinPrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_salida_LinPrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_salida_LinPrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_salida_LinPrecio'].errors?.['apiError']">{{cliForm.get('fac_salida_LinPrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div> <!-- row -->






                                                    <div class="row">

                                                        <!-- fac_salida_UnidPrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_salida_UnidPrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title="  Precio por unidad de cada salida:">
                                                                    Precio por unidad €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_salida_UnidPrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_salida_UnidPrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_salida_UnidPrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_salida_UnidPrecio'].errors?.['apiError']">{{cliForm.get('fac_salida_UnidPrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_salida_PalePrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_salida_PalePrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title=" Precio por cada Carga de palé">
                                                                    Precio por palé €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_salida_PalePrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_salida_PalePrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_salida_PalePrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_salida_PalePrecio'].errors?.['apiError']">{{cliForm.get('fac_salida_PalePrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_salida_gestLotePrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_salida_gestLotePrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title=" Precio por cada linea de la salida que gestiona lote">
                                                                    Precio por lote €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_salida_gestLotePrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_salida_gestLotePrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_salida_gestLotePrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_salida_gestLotePrecio'].errors?.['apiError']">{{cliForm.get('fac_salida_gestLotePrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <!-- SEPARADOR -->
                                                        <div class="col-md-2">
                                                        </div>



                                                        <!-- fac_salida_PrecioEtiqueta -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_salida_PrecioEtiqueta'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio Etiquetado €:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_salida_PrecioEtiqueta"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_salida_PrecioEtiqueta'].errors }">
                                                                <div *ngIf="enviado && f['fac_salida_PrecioEtiqueta'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_salida_PrecioEtiqueta'].errors?.['apiError']">{{cliForm.get('fac_salida_PrecioEtiqueta')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_salida_PrecioFijo -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_salida_PrecioFijo'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title="Precio fijo por cada salida, independiente de los otros valores">
                                                                    Precio Fijo €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>


                                                                <input type="text"
                                                                       formControlName="fac_salida_PrecioFijo"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_salida_PrecioFijo'].errors }">
                                                                <div *ngIf="enviado && f['fac_salida_PrecioFijo'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_salida_PrecioFijo'].errors?.['apiError']">{{cliForm.get('fac_salida_PrecioFijo')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div> <!-- FIN ROW -->


                                                    <div class="row">

                                                        <!-- SEPARADOR -->
                                                        <div class="col-md-10">
                                                        </div>


                                                        <!-- fac_salida_LiteralPrecioFijo -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_salida_LiteralPrecioFijo'].errors }">

                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title=" El literál que aparecerá en los conceptos factuables como 'precio fijo'">
                                                                    Literal en precio fijo:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_salida_LiteralPrecioFijo"
                                                                       class="form-control"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_salida_LiteralPrecioFijo'].errors }">
                                                                <small><i>('Por salida')</i></small>
                                                                <div *ngIf="enviado && f['fac_salida_LiteralPrecioFijo'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_salida_LiteralPrecioFijo'].errors?.['apiError']">{{cliForm.get('fac_salida_LiteralPrecioFijo')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div> <!-- FIN ROW -->



                                                </div><!-- FIN SALIDAS -->

                                            </div><!-- FIN card-body-->
                                        </div><!-- FIN card-->




                                        <div class="row">
                                            <div class="col-md-12 ">
                                                <h4 class="text-info">Packaging:</h4>
                                            </div>
                                        </div>


                                        <div class="card border border-info rounded">
                                            <div class="card-body">

                                                <div class="div"> <!-- PACKAGING -->

                                                    <div class="row">

                                                        <!-- fac_pack_PrecioFijo -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_PrecioFijo'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title="Precio fijo de PACKAGING para todos los envíos">
                                                                    Precio fijo:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_PrecioFijo"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_PrecioFijo'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_PrecioFijo'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_PrecioFijo'].errors?.['apiError']">{{cliForm.get('fac_pack_PrecioFijo')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_pack_horas -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_horas'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title="Precio por cada hora dedicada al PACKAGING">
                                                                    Precio hora:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_horas"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_horas'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_horas'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_horas'].errors?.['apiError']">{{cliForm.get('fac_pack_horas')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>






                                                    <div class="row">

                                                        <!-- fac_pack_bolsa01 -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_bolsa01'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio bolsa pqueña:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_bolsa01"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_bolsa01'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_bolsa01'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_bolsa01'].errors?.['apiError']">{{cliForm.get('fac_pack_bolsa01')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- fac_pack_bolsa02 -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_bolsa02'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio bolsa mediana:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_bolsa02"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_bolsa01'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_bolsa01'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_bolsa01'].errors?.['apiError']">{{cliForm.get('fac_pack_bolsa01')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- fac_pack_bolsa03 -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_bolsa03'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio bolsa grande:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_bolsa03"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_bolsa03'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_bolsa03'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_bolsa03'].errors?.['apiError']">{{cliForm.get('fac_pack_bolsa03')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>





                                                        <!-- fac_pack_caja01 -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_caja01'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio caja pqueña:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_caja01"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_caja01'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_caja01'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_caja01'].errors?.['apiError']">{{cliForm.get('fac_pack_caja01')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- fac_pack_caja02 -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_caja02'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio caja mediana:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_caja02"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_caja01'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_caja01'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_caja01'].errors?.['apiError']">{{cliForm.get('fac_pack_caja01')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- fac_pack_caja03 -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_caja03'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio caja grande:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_caja03"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_caja03'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_caja03'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_caja03'].errors?.['apiError']">{{cliForm.get('fac_pack_caja03')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                    <div class="row">




                                                        <!-- fac_pack_pale01 -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_pale01'].errors }">
                                                                <label class="form-control-label">
                                                                    Palé NO retornable:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_pale01"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_pale01'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_pale01'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_pale01'].errors?.['apiError']">{{cliForm.get('fac_pack_pale01')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_pack_pale02 -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_pale02'].errors }">
                                                                <label class="form-control-label">
                                                                    Palé europeo:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_pale02"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_pale02'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_pale02'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_pale02'].errors?.['apiError']">{{cliForm.get('fac_pack_pale02')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_pack_pale03 -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_pale03'].errors }">
                                                                <label class="form-control-label">
                                                                    Palé americano:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_pale03"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_pale03'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_pale03'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_pale03'].errors?.['apiError']">{{cliForm.get('fac_pack_pale03')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_pack_pale04 -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_pale04'].errors }">
                                                                <label class="form-control-label">
                                                                    Palé de plástico:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_pale04"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_pale04'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_pale04'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_pale04'].errors?.['apiError']">{{cliForm.get('fac_pack_pale04')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- fac_pack_pale05 -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_pale05'].errors }">
                                                                <label class="form-control-label">
                                                                    Palé ignífugo:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_pale05"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_pale05'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_pale05'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_pale05'].errors?.['apiError']">{{cliForm.get('fac_pack_pale05')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>








                                                    </div>
                                                    <div class="row">




                                                        <!-- fac_pack_burbuja -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_burbuja'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio Burbujas:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_burbuja"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_burbuja'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_burbuja'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_burbuja'].errors?.['apiError']">{{cliForm.get('fac_pack_burbuja')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_pack_viruta -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_viruta'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio virutas:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_viruta"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_viruta'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_viruta'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_viruta'].errors?.['apiError']">{{cliForm.get('fac_pack_viruta')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- fac_pack_papel -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_papel'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio papel kraft:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_papel"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_papel'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_papel'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_papel'].errors?.['apiError']">{{cliForm.get('fac_pack_papel')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- fac_pack_otros -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_pack_otros'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio otros:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_pack_otros"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_otros'].errors }">
                                                                <div *ngIf="enviado && f['fac_pack_otros'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_pack_otros'].errors?.['apiError']">{{cliForm.get('fac_pack_otros')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div> <!-- row -->




                                                </div><!-- FIN PACKAGING -->

                                            </div><!-- FIN card body-->
                                        </div><!-- FIN card-->

                                    </div>
                                </div>
                            </div> <!-- Pestaña de Salidas -->


                            <!-- Pestaña de Devoluciones -->
                            <div class="tab-pane"
                                 id="devoluciones"
                                 role="tabpanel">
                                <div class="row mt-4">
                                    <div class="col-md-12">


                                        <div class="row">
                                            <div class="col-md-12 ">
                                                <h4 class="text-info">Devoluciones:</h4>
                                            </div>
                                        </div>

                                        <div class="card border border-info rounded">
                                            <div class="card-body">

                                                <div class="div"> <!-- DEVOLUCIONES -->


                                                    <div class="row">


                                                        <div class="col-md-6 text-right">
                                                            <br>
                                                            <h6><i>Por líneas (referencias) - Por línea en albarán de devolución</i></h6>
                                                        </div>


                                                        <!-- fac_devol_MinLin -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_devol_MinLin'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title="Número Mínimo de líneas para cada devolución">
                                                                    Primeras Líneas:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_devol_MinLin"
                                                                       class="form-control"
                                                                       mask="separator.0"
                                                                       suffix=" Líneas"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_devol_MinLin'].errors }">
                                                                <div *ngIf="enviado && f['fac_devol_MinLin'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_devol_MinLin'].errors?.['apiError']">{{cliForm.get('fac_devol_MinLin')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <!-- fac_devol_MinPrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_devol_MinPrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title=" Precio por las primeras líneas">
                                                                    Por primeras líneas €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_devol_MinPrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_devol_MinPrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_devol_MinPrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_devol_MinPrecio'].errors?.['apiError']">{{cliForm.get('fac_devol_MinPrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>





                                                        <!-- fac_devol_LinPrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_devol_LinPrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title=" Precio de cada línea por encima del mínimo de líneas">
                                                                    Por línea adicional €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_devol_LinPrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_devol_LinPrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_devol_LinPrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_devol_LinPrecio'].errors?.['apiError']">{{cliForm.get('fac_devol_LinPrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div> <!-- row -->





                                                    <div class="row">


                                                        <!-- fac_devol_UnidPrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_devol_UnidPrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title="  Precio por unidad de cada devolución:">
                                                                    Precio por unidad €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_devol_UnidPrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_devol_UnidPrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_devol_UnidPrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_devol_UnidPrecio'].errors?.['apiError']">{{cliForm.get('fac_devol_UnidPrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_devol_PalePrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_devol_PalePrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title=" Precio por cada Descarga de palé">
                                                                    Precio por palé €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_devol_PalePrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_devol_PalePrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_devol_PalePrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_devol_PalePrecio'].errors?.['apiError']">{{cliForm.get('fac_devol_PalePrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_devol_gestLotePrecio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_devol_gestLotePrecio'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title=" Precio por cada linea de la devolución que gestiona lote">
                                                                    Precio por lote €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_devol_gestLotePrecio"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_devol_gestLotePrecio'].errors }">
                                                                <div *ngIf="enviado && f['fac_devol_gestLotePrecio'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_devol_gestLotePrecio'].errors?.['apiError']">{{cliForm.get('fac_devol_gestLotePrecio')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>





                                                        <!-- fac_devol_PrecioHora -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_devol_PrecioHora'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio hora €:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_devol_PrecioHora"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_devol_PrecioHora'].errors }">
                                                                <div *ngIf="enviado && f['fac_devol_PrecioHora'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_devol_PrecioHora'].errors?.['apiError']">{{cliForm.get('fac_devol_PrecioHora')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <!-- fac_devol_PrecioEtiqueta -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_devol_PrecioEtiqueta'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio Etiquetado €:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_devol_PrecioEtiqueta"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_devol_PrecioEtiqueta'].errors }">
                                                                <div *ngIf="enviado && f['fac_devol_PrecioEtiqueta'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_devol_PrecioEtiqueta'].errors?.['apiError']">{{cliForm.get('fac_devol_PrecioEtiqueta')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_devol_PrecioFijo -->
                                                        <div class="col-md-2">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_devol_PrecioFijo'].errors }">
                                                                <label class="form-control-label"
                                                                       data-toggle="tooltip"
                                                                       title="Precio fijo por cada devolución, independiente de los otros valores">
                                                                    Precio Fijo €:
                                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                                </label>


                                                                <input type="text"
                                                                       formControlName="fac_devol_PrecioFijo"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_devol_PrecioFijo'].errors }">
                                                                <div *ngIf="enviado && f['fac_devol_PrecioFijo'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_devol_PrecioFijo'].errors?.['apiError']">{{cliForm.get('fac_devol_PrecioFijo')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div> <!-- row -->




                                                </div><!-- FIN DEVOLUCIONES -->

                                            </div><!-- FIN card body-->
                                        </div><!-- FIN card-->

                                    </div>
                                </div>
                            </div><!-- Pestaña de Devoluciones -->




                            <!-- Pestaña de Seguro -->
                            <div class="tab-pane"
                                 id="seguro"
                                 role="tabpanel">
                                <div class="row mt-4">
                                    <div class="col-md-12">



                                        <div class="row">
                                            <div class="col-md-12 ">
                                                <h4 class="text-info">Seguro:</h4>
                                            </div>
                                        </div>

                                        <div class="card border border-info rounded">
                                            <div class="card-body">


                                                <div class="div"> <!-- SEGURO -->



                                                    <div class="row">

                                                        <!-- fac_seguro_eur -->
                                                        <div class="col-md-3">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_seguro_eur'].errors }">
                                                                <label class="form-control-label">
                                                                    Importe Precio fijo:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_seguro_eur"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_seguro_eur'].errors }">
                                                                <div *ngIf="enviado && f['fac_seguro_eur'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_seguro_eur'].errors?.['apiError']">{{cliForm.get('fac_seguro_eur')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_seguro_valor -->
                                                        <div class="col-md-3">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_seguro_valor'].errors }">
                                                                <label class="form-control-label">
                                                                    Valor de la mercancía del cliente:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_seguro_valor"
                                                                       (input)="calcularSeguro()"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_seguro_valor'].errors }">
                                                                <div *ngIf="enviado && f['fac_seguro_valor'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_seguro_valor'].errors?.['apiError']">{{cliForm.get('fac_seguro_valor')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_seguro_porcentaje -->
                                                        <div class="col-md-3">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_seguro_porcentaje'].errors }">
                                                                <label class="form-control-label">
                                                                    Porcentaje sobre el valor de la mercancía:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_seguro_porcentaje"
                                                                       (input)="calcularSeguro()"
                                                                       class="form-control"
                                                                       mask="separator.4"
                                                                       suffix=" %"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_seguro_porcentaje'].errors }">
                                                                <div *ngIf="enviado && f['fac_seguro_porcentaje'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_seguro_porcentaje'].errors?.['apiError']">{{cliForm.get('fac_seguro_porcentaje')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_seguro_calculado -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label">Importe del seguro:</label>
                                                                <div class="input-group">
                                                                    <input type="text"
                                                                           formControlName="fac_seguro_calculado"
                                                                           class="form-control"
                                                                           mask="separator.2"
                                                                           suffix=" €"
                                                                           readonly>
                                                                </div>
                                                            </div>
                                                        </div>






                                                    </div> <!-- row -->



                                                </div><!-- FIN SEGURO -->

                                            </div><!-- FIN card body-->
                                        </div><!-- FIN card-->


                                    </div>
                                </div>
                            </div> <!-- Pestaña de Seguro -->





                            <!-- Pestaña de Almacenaje -->
                            <div class="tab-pane"
                                 id="almacenaje"
                                 role="tabpanel">
                                <div class="row mt-4">
                                    <div class="col-md-12"> <!-- ALMACENAJE -->


                                        <div class="row">
                                            <div class="col-md-12 ">
                                                <h4 class="text-info">Almacenaje:</h4>
                                            </div>
                                        </div>


                                        <div class="card border border-info rounded">
                                            <div class="card-body">


                                                <div class="div"><!-- FACTURAR por dia/mes-->

                                                    <div class="row">


                                                        <!-- fac_alma_dia -->
                                                        <div class="col-md-3">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_alma_dia'].errors }">
                                                                <div class="demo-checkbox">
                                                                    <input id="fac_alma_dia"
                                                                           type="checkbox"
                                                                           formControlName="fac_alma_dia"
                                                                           (change)="fac_alma_diaChange($event)"
                                                                           class="filled-in" />
                                                                    <label for="fac_alma_dia">Facturar por día</label>
                                                                </div>

                                                                <div *ngIf="enviado && f['fac_alma_dia'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_alma_dia'].errors?.['apiError']">{{cliForm.get('fac_alma_dia')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_alma_finMes -->
                                                        <div class="col-md-3">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_alma_finMes'].errors }">
                                                                <div class="demo-checkbox">
                                                                    <input id="fac_alma_finMes"
                                                                           type="checkbox"
                                                                           formControlName="fac_alma_finMes"
                                                                           (change)="fac_alma_finMesChange($event)"
                                                                           class="filled-in" />
                                                                    <label for="fac_alma_finMes">Facturar por media mensual</label>
                                                                </div>

                                                                <div *ngIf="enviado && f['fac_alma_finMes'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_alma_finMes'].errors?.['apiError']">{{cliForm.get('fac_alma_finMes')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="col-md-6">
                                                            <span *ngIf="muestrafacDia"
                                                                  class="text-muted small">
                                                                (Sumar la búsqueda del Total de ocupación del tipo seleccionado en almacén entre las fechas desde y hasta) * precio
                                                            </span>
                                                            <span *ngIf="!muestrafacDia"
                                                                  class="text-muted small">
                                                                (Sumar la búsqueda del Total de ocupación del tipo seleccionado en almacén entre las fechas desde y hasta / hasta-desde) * precio
                                                            </span>
                                                        </div>

                                                    </div> <!-- row -->



                                                    <div class="row">

                                                        <div class="col-md-3"> </div>

                                                        <!-- fac_espa_pale1_tipo -->
                                                        <div class="col-md-3">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_espa_pale1_tipo'].errors }">
                                                                <label class="form-control-label">Tipo de Pale 1</label>
                                                                <select formControlName="fac_espa_pale1_tipo"
                                                                        (change)="fac_espa_pale1_tipoChange(getSelectedTipoPale($event))"
                                                                        class="form-control"
                                                                        [ngClass]="{ 'form-control-danger': enviado && f['fac_espa_pale1_tipo'].errors }">
                                                                    <option [value]="null">Selecciona...</option>
                                                                    <option *ngFor="let tipoPale of tiposPaleList"
                                                                            [value]="tipoPale.id">
                                                                        {{tipoPale.nombre}}
                                                                    </option>
                                                                </select>
                                                                <div *ngIf="enviado && f['fac_espa_pale1_tipo'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_espa_pale1_tipo'].errors['required']">Obligatorio</div>
                                                                    <div *ngIf="cliForm.get('fac_espa_pale1_tipo')?.errors?.['apiError']">{{
                                                                        cliForm.get('fac_espa_pale1_tipo')?.errors?.['apiError'] }}</div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <!-- fac_espa_pale2_tipo -->
                                                        <div class="col-md-3">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_espa_pale2_tipo'].errors }">
                                                                <label class="form-control-label">Tipo de Pale 2</label>
                                                                <select formControlName="fac_espa_pale2_tipo"
                                                                        (change)="fac_espa_pale2_tipoChange(getSelectedTipoPale($event))"
                                                                        class="form-control"
                                                                        [ngClass]="{ 'form-control-danger': enviado && f['fac_espa_pale2_tipo'].errors }">
                                                                    <option [value]="null">Selecciona...</option>
                                                                    <option *ngFor="let tipoPale of tiposPaleList"
                                                                            [value]="tipoPale.id">
                                                                        {{tipoPale.nombre}}
                                                                    </option>
                                                                </select>
                                                                <div *ngIf="enviado && f['fac_espa_pale2_tipo'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_espa_pale2_tipo'].errors['required']">Obligatorio</div>
                                                                    <div *ngIf="cliForm.get('fac_espa_pale2_tipo')?.errors?.['apiError']">{{
                                                                        cliForm.get('fac_espa_pale2_tipo')?.errors?.['apiError'] }}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div> <!-- row -->



                                                </div><!-- FIN FACTURAR por dia/mes-->


                                                <div class="div"> <!-- ESPACIO OCUPADO DIA-->


                                                    <div class="row">
                                                        <div class="col-md-12 ">
                                                            <h4 class="text-info">Espacio ocupado, precio por día:</h4>
                                                        </div>
                                                    </div>


                                                    <div class="row">

                                                        <!-- fac_espa_cajetin_dia -->
                                                        <div class="col-md-3">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_espa_cajetin_dia'].errors }">
                                                                <label class="form-control-label">
                                                                    Por cajetin €:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_espa_cajetin_dia"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_espa_cajetin_dia'].errors }">
                                                                <div *ngIf="enviado && f['fac_espa_cajetin_dia'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_espa_cajetin_dia'].errors?.['apiError']">{{cliForm.get('fac_espa_cajetin_dia')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <!-- fac_espa_pale1_dia -->
                                                        <div class="col-md-3">
                                                            <div *ngIf="paleTipo1.nombre"
                                                                 class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_espa_pale1_dia'].errors }">
                                                                <label class="form-control-label">
                                                                    Por palé {{paleTipo1.nombre}} €:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_espa_pale1_dia"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_espa_pale1_dia'].errors }">
                                                                <div *ngIf="enviado && f['fac_espa_pale1_dia'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_espa_pale1'].errors?.['apiError']">{{cliForm.get('fac_espa_pale1_dia')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- fac_espa_pale2_dia -->
                                                        <div class="col-md-3">
                                                            <div *ngIf="paleTipo2.nombre"
                                                                 class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_espa_pale2_dia'].errors }">
                                                                <label class="form-control-label">
                                                                    Por palé {{paleTipo2.nombre}} €:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_espa_pale2_dia"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_espa_pale2_dia'].errors }">
                                                                <div *ngIf="enviado && f['fac_espa_pale2_dia'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_espa_pale2'].errors?.['apiError']">{{cliForm.get('fac_espa_pale2')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div> <!-- row -->



                                                </div><!-- FIN ESPACIO OCUPADO DIA -->


                                                <div class="div"> <!-- ESPACIO OCUPADO MES-->


                                                    <div class="row">
                                                        <div class="col-md-12 ">
                                                            <h4 class="text-info">Espacio ocupado, precio por media mensual:</h4>
                                                        </div>
                                                    </div>


                                                    <div class="row">

                                                        <!-- fac_espa_cajetin_mes -->
                                                        <div class="col-md-3">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_espa_cajetin_mes'].errors }">
                                                                <label class="form-control-label">
                                                                    Por cajetin €:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_espa_cajetin_mes"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_espa_cajetin_mes'].errors }">
                                                                <div *ngIf="enviado && f['fac_espa_cajetin_mes'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_espa_cajetin_mes'].errors?.['apiError']">{{cliForm.get('fac_espa_cajetin_mes')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <!-- fac_espa_pale1_mes -->
                                                        <div class="col-md-3">
                                                            <div *ngIf="paleTipo1.nombre"
                                                                 class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_espa_pale1_mes'].errors }">
                                                                <label class="form-control-label">
                                                                    Por palé {{paleTipo1.nombre}} €:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_espa_pale1_mes"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_espa_pale1_mes'].errors }">
                                                                <div *ngIf="enviado && f['fac_espa_pale1_mes'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_espa_pale1_mes'].errors?.['apiError']">{{cliForm.get('fac_espa_pale1_mes')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <!-- fac_espa_pale2_mes -->
                                                        <div class="col-md-3">
                                                            <div *ngIf="paleTipo2.nombre"
                                                                 class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_espa_pale2_mes'].errors }">
                                                                <label class="form-control-label">
                                                                    Precio palé {{paleTipo2.nombre}} €:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_espa_pale2_mes"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_espa_pale2_mes'].errors }">
                                                                <div *ngIf="enviado && f['fac_espa_pale2_mes'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_espa_pale2_mes'].errors?.['apiError']">{{cliForm.get('fac_espa_pale2_mes')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div> <!-- row -->



                                                </div><!-- FIN ESPACIO OCUPADO MES-->






                                                <div class="div"> <!-- UNIDADES FIJAS x MES -->


                                                    <div class="row">
                                                        <div class="col-md-12 ">
                                                            <h4 class="text-info">Espacio ocupado, unidades FIJAS para media mensual: <br><small>(Sustituyen a las sumadas del periodo a facturar)</small></h4>
                                                        </div>
                                                    </div>


                                                    <div class="row">

                                                        <!-- fac_espa_cajetin_unidadesMes -->
                                                        <div class="col-md-3">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_espa_cajetin_unidadesMes'].errors }">
                                                                <label class="form-control-label">Cajetines:</label>

                                                                <input type="text"
                                                                       formControlName="fac_espa_cajetin_unidadesMes"
                                                                       class="form-control"
                                                                       mask="separator.0"
                                                                       suffix=" Unidades"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_espa_cajetin_unidadesMes'].errors }">
                                                            </div>
                                                        </div>



                                                        <!-- fac_espa_pale1_unidadesMes -->
                                                        <div class="col-md-3">
                                                            <div *ngIf="paleTipo1.nombre"
                                                                 class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_espa_pale1_unidadesMes'].errors }">
                                                                <label class="form-control-label">Palés {{paleTipo1.nombre}}:</label>

                                                                <input type="text"
                                                                       formControlName="fac_espa_pale1_unidadesMes"
                                                                       class="form-control"
                                                                       mask="separator.0"
                                                                       suffix=" Unidades"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_espa_pale1_unidadesMes'].errors }">
                                                            </div>
                                                        </div>



                                                        <!-- fac_espa_pale2_unidadesMes -->
                                                        <div class="col-md-3">
                                                            <div *ngIf="paleTipo2.nombre"
                                                                 class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_espa_pale2_unidadesMes'].errors }">
                                                                <label class="form-control-label">Palés {{paleTipo2.nombre}}: </label>

                                                                <input type="text"
                                                                       formControlName="fac_espa_pale2_unidadesMes"
                                                                       class="form-control"
                                                                       mask="separator.0"
                                                                       suffix=" Unidades"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_espa_pale2_unidadesMes'].errors }">
                                                            </div>
                                                        </div>



                                                    </div> <!-- row -->



                                                </div><!-- FIN UNIDADES FIJAS x MES -->



                                            </div><!-- card body -->
                                        </div><!-- card -->

                                        <div class="div"> <!-- MOVIMIENTO INTERNO -->


                                            <div class="row">
                                                <div class="col-md-12 ">
                                                    <h4 class="text-info">Por Movimientos internos facturables:</h4>
                                                </div>
                                            </div>

                                            <div class="card border border-info rounded">
                                                <div class="card-body">




                                                    <div class="row">


                                                        <!-- fac_movimiento_pale -->
                                                        <div class="col-md-4">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_movimiento_pale'].errors }">
                                                                <label class="form-control-label">
                                                                    Movimiento de un palé:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_movimiento_pale"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_movimiento_pale'].errors }">
                                                                <div *ngIf="enviado && f['fac_movimiento_pale'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_movimiento_pale'].errors?.['apiError']">{{cliForm.get('fac_movimiento_pale')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>





                                                        <!-- fac_movimiento_referencia -->
                                                        <div class="col-md-4">
                                                            <div class="form-group"
                                                                 [ngClass]="{ 'has-danger': enviado && f['fac_movimiento_referencia'].errors }">
                                                                <label class="form-control-label">
                                                                    Por Movimiento de referencias:
                                                                </label>

                                                                <input type="text"
                                                                       formControlName="fac_movimiento_referencia"
                                                                       class="form-control"
                                                                       mask="separator.2"
                                                                       suffix=" €"
                                                                       [ngClass]="{ 'form-control-danger': enviado && f['fac_movimiento_referencia'].errors }">
                                                                <div *ngIf="enviado && f['fac_movimiento_referencia'].errors"
                                                                     class="form-control-feedback">
                                                                    <div *ngIf="f['fac_movimiento_referencia'].errors?.['apiError']">{{cliForm.get('fac_movimiento_referencia')?.errors?.['apiError']}}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div> <!-- row -->


                                                </div><!-- card body -->
                                            </div><!-- card -->

                                        </div><!-- FIN MOVIMIENTO INTERNO -->




                                    </div><!-- FIN ALMACENAJE -->

                                </div>
                            </div><!-- Pestaña de Almacenaje -->



                        </div>
                    </div>



                    <hr>

                    <div class="d-flex">
                        <button [disabled]="loading"
                                type="button"
                                class="btn waves-effect waves-light btn-rounded btn-secondary"
                                [routerLink]="'/panel/clientes_lista'"
                                data-dismiss="modal">Cancelar</button>
                        <h6 class="card-subtitle ml-4 ">
                            <li *ngIf="cliente.created_at"> Creado hace {{cliente.created_at! | friendlyDdmmyy}}</li>
                            <li *ngIf="cliente.updated_at"> Última modificación hace {{cliente.updated_at! | friendlyDdmmyy}}</li>
                        </h6>
                        <button [disabled]="loading"
                                type="submit"
                                class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                            <i *ngIf="loading"
                               class="fa fa-spinner fa-spin"></i>
                            Enviar</button>
                    </div>





                </form>
            </div>
        </div>
    </div>
</div>