// módulos 
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionType } from '@swimlane/ngx-datatable';

// interfaces
import { IEntrada } from 'src/app/interfaces/entrada';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';
import { IUbicacion } from 'src/app/interfaces/ubicacion';

// servicios
import { UbicacionesService } from './../../../services/datos/ubicaciones.service';
import { ArticulosService } from 'src/app/services/datos/articulos.service';
import { EntradasService } from 'src/app/services/datos/entradas.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import CustomVal from 'src/app/providers/CustomValidators';
import FuncLib from 'src/app/providers/funcionesLib';
import { AlmacenesService } from 'src/app/services/datos/almacenes.service';

@Component({
  selector: 'app-entrada-ubica-pale',
  templateUrl: './entrada-ubica-pale.component.html',
  styleUrls: ['./entrada-ubica-pale.component.scss']
})
export class EntradaUbicaPaleComponent implements OnInit {

  // para poder simular click en el tab tabUbi1Link
  @ViewChild('tabUbi1Link', { static: false }) tabUbi1Link!: ElementRef;

  private entrada_id: number | undefined;
  public entrada_pale: number | null = null;

  public entrada: IEntrada = {};

  public ubiList: IUbicacion[] = [];
  public ubiAlturas: string[] = [];

  selectedAltura: string = ''; // Variable para almacenar la altura seleccionada
  filteredUbiList: any[] = [];  // Lista filtrada

  SelectionType = SelectionType;
  selected = [];

  opcionesList: IListaOpciones = {};

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: ''
  }

  public medidasForm: FormGroup = new FormGroup({});
  public ubiForm: FormGroup = new FormGroup({});

  public enviado: boolean = false;
  public enviadoU: boolean = false;
  public loading: boolean = false;
  public avisoVacio: boolean = false;

  private funcLib: FuncLib = new FuncLib();
  public codUbiDecript: string = '';

  ultimoAlmacen: string = '';
  public infoAlmacenObj: any = {};

  constructor(
    private entradaSrv: EntradasService,
    private ubicacionesSrv: UbicacionesService,
    public articulosSrv: ArticulosService,
    private almacenesSrv: AlmacenesService,
    private fb: FormBuilder,
    private notificacionesSrv: NotificacionesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }


  ngOnInit(): void {

    this.getParametrosUrl();

    // Definir el form de medidas
    this.medidasForm = this.fb.group({
      alto: ['', [Validators.required, CustomVal.esEntero]],
      ancho: ['', [Validators.required, CustomVal.esEntero]],
      largo: ['', [Validators.required, CustomVal.esEntero]],
      peso: ['']
    })

    // Definir el form de ubicación
    this.ubiForm = this.fb.group({
      ubicacion: ['', [
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12),
        CustomVal.empiezaPor('ubicacion', '0')
      ]],
    })

    // Suscribirse a los cambios en el campo almacen destino
    this.ubiForm.get('ubicacion')?.valueChanges.subscribe(value => {
      if (typeof value === 'string' && value.length >= 2) {
        const nuevoAlmacen = value.substring(0, 2);
        if (this.ultimoAlmacen !== nuevoAlmacen) {
          this.ultimoAlmacen = nuevoAlmacen;
          this.onCambioAlmacen(nuevoAlmacen);
        }
      }
      if (!value) this.infoAlmacenObj = {};
    });
  }

  getParametrosUrl() {
    // Suscribirse a los parámetros de la url 
    this.activatedRoute.params.subscribe(({ id, pale }) => {
      this.entrada_id = id;
      this.entrada_pale = pale;

      this.getEntrada();
    })
  }


  getEntrada() {
    // Cargar los valores de la entrada para ese palé
    this.opcionesList.id = this.entrada_id;
    this.loading = true;

    this.entradaSrv.getLista(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;

        console.log('__getEntrada() resp', resp);

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          this.router.navigate(['./panel/entradas_lista']);
        }

        const datosFiltrados = resp.data[0].detall
          .filter((item: any) => item.pale == this.entrada_pale);

        this.entrada = {
          ...resp.data[0],
          detall: datosFiltrados, // actualiza "detall" con los elementos filtrados para el palé
        };

        console.log('getEntrada', this.entrada);

        if (!this.validarLotes()) {
          this.notificacionesSrv.aviso('error', 'No es posible ubicar el palé ya que contiene el mismo artículo con diferentes lotes')
          this.router.navigate(['./panel/entradas_lista']);
        }

        // Actualizar los validadores del ubiForm
        this.ubiForm.get('ubicacion')?.setValidators([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
          CustomVal.empiezaPor('ubicacion', this.entrada.almCodigo!) // Asignar el nuevo valor de comparación
        ]);
        this.ubiForm.get('ubicacion')?.updateValueAndValidity();

      })

  }

  // Validar que no existan lotes diferentes para un mismo artículo en el el detall de la entrada
  validarLotes(): boolean {
    const articlesMap = new Map<string, Set<string>>();

    for (const detail of this.entrada.detall!) {
      const articleId = String(detail.id_articulo);
      const lote = String(detail.lote);

      if (!articlesMap.has(articleId)) {
        articlesMap.set(articleId, new Set());
      }

      const lotesSet = articlesMap.get(articleId);
      if (lotesSet) {
        lotesSet.add(lote);
        if (lotesSet.size > 1) {
          return false; // Encontrado el mismo artículo con diferentes lotes
        }
      }
    }

    return true; // Todos los artículos tienen lotes únicos
  }




  enviarMedidas() {
    this.enviado = true;

    if (this.medidasForm.invalid) {
      console.log("ERRORES:", this.medidasForm);
      return;
    }

    this.updateMedidas()
    this.buscaSitio()

  }



  updateMedidas() {
    //Actualizar medidas en el registro de detall de la entrada
    const datos = {
      ...this.medidasForm.value,
      id_entrada: this.entrada.id,
      pale: this.entrada_pale
    };

    this.loading = true;
    this.entradaSrv.updateMedidas(datos).subscribe(resp => {
      this.loading = false;
    })
  }


  buscaSitio() {
    // Buscar ubicaciones posibles
    const datos = {
      ...this.medidasForm.value,
      id_almacen: this.entrada.id_almacen,
    };
    this.loading = true;
    this.avisoVacio = false;
    this.filteredUbiList = [];
    this.ubiAlturas = [];

    this.ubicacionesSrv.buscaSitio(datos)
      .subscribe(resp => {
        this.loading = false;

        console.log('__buscaSitio() datos', datos);
        console.log('__buscaSitio() resp', resp);

        if (resp.error) {
          this.notificacionesSrv.aviso('warning', resp.mensaje);
          console.log(resp.mensaje);
          this.ubiList = [];

          for (let controlName in resp.mensaje) {
            const control = this.medidasForm.get(controlName);
            if (control) control.setErrors({ 'apiError': resp.mensaje[controlName] });
          }

        } else {
          this.notificacionesSrv.aviso('info', `${resp.mensaje}`);
          this.ubiList = resp.data;

          if (!this.ubiList.length) this.avisoVacio = true;

          console.log('__buscaSitio() ubiList', this.ubiList);

          // cargar las alturas posibles de ubicaciones
          this.filteredUbiList = [...this.ubiList]; // Inicializar el filtro de alturas con todos los datos
          this.ubiAlturas = [...new Set(this.ubiList.map((ubi: any) => ubi.codigo.substr(6, 2)))];
          this.ubiAlturas.sort();

          // Simular 'click' en la pestaña de medidas tabUbi1Link
          const tab1 = this.tabUbi1Link.nativeElement;
          if (tab1) tab1.click();

        }
      })
  }


  enviarUbi() {
    this.enviadoU = true;

    if (this.ubiForm.invalid) {
      console.log("ERRORES:", this.ubiForm);
      return;
    }

    const datos = {
      ubicacion: this.ubiForm.get('ubicacion')?.value,
      entrada_pale: this.entrada_pale,
      entrada: this.entrada
    };

    console.log(' enviarUbi datos', datos);

    // this.loading = true;

    console.log('--> ubicaPale(datos)', datos);

    this.ubicacionesSrv.ubicaPale(datos)
      .subscribe(resp => {
        this.loading = false;
        console.log('enviarUbi', resp);

        if (resp.error) {
          //this.notificacionesSrv.aviso('warning', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.ubiForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', `${resp.mensaje}`);
          this.router.navigate(['./panel/entradas_lista']);
        }


      });
  }


  onRowUbiClick(rowEvent: any) {
    const row = rowEvent.selected[0]; // Obtiene el objeto de la línea seleccionada
    console.log('seleccionado', row)
    this.ubiForm.patchValue({
      ubicacion: row.codigo
    });
    this.cambiaUbi();
  }


  cambiaUbi() {
    const ubicacion = this.ubiForm.get('ubicacion')?.value;
    this.codUbiDecript = this.funcLib.CodUbiDecript(ubicacion);
  }

  // Cambia el almacen de una ubicación
  onCambioAlmacen(nuevoAlm: string) {
    console.log('Las 2 primeras posiciones de almacen_desde han cambiado:', nuevoAlm);
    this.almacenesSrv.detallCod(nuevoAlm).subscribe(resp => {
      console.log('__onCambioAlmacen', resp);
      this.infoAlmacenObj = resp.data;
    })
  }


  get f() {
    return this.medidasForm.controls;
  }

  get fu() {
    return this.ubiForm.controls;
  }


  ///   AUX   ///

  onSelectUbiAltura(): void {
    this.filteredUbiList = this.selectedAltura
      ? this.ubiList.filter((ubi: any) => ubi.codigo.substr(6, 2) === this.selectedAltura)
      : [...this.ubiList]; // Mostrar todos si no se selecciona nada

    console.log('__onSelectUbiAltura() Filtrado por altura filteredUbiList:', this.filteredUbiList);

  }
}
