<div *ngIf="loading"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Cargando...</h3>
</div>

<div class="row animated fadeIn fast">

    <div class="col-lg-12">
        <div class="card">

            <div class="card-body">

                <form class="form p-t-20"
                      [formGroup]="entradaForm"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">
                    <div class="form-body">

                        <div class="row">

                            <!-- id_almacen -->
                            <div *ngIf="entrada.swValidado !=='2'"
                                 class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['id_almacen'].errors }">
                                    <label class="form-control-label">Almacén:</label>
                                    <select formControlName="id_almacen"
                                            (change)="cambiaAlmacen()"
                                            class="form-control form-control-sm"
                                            [ngClass]="{ 'form-control-danger': enviado && f['id_almacen'].errors }">
                                        <option *ngFor="let almacen of almacenesList"
                                                [value]="almacen.id">
                                            {{almacen.nombre}}
                                        </option>
                                    </select>
                                    <div *ngIf="enviado && f['id_almacen'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['id_almacen'].errors['required']">Obligatorio</div>
                                        <div *ngIf="entradaForm.get('id_almacen')?.errors?.['apiError']">{{
                                            entradaForm.get('id_almacen')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>




                            <!-- id_almacen (NO editable) -->
                            <div *ngIf="entrada.swValidado === '2'"
                                 class="col-md-2">
                                <div class="form-group">
                                    <label class="form-control-label">Almacén:</label>
                                    <input type="text"
                                           class="form-control form-control-sm"
                                           value="{{almNombre}}"
                                           disabled>
                                </div>
                            </div>



                            <!-- ubiPlaya -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['ubiPlaya'].errors }">
                                    <label class="form-control-label">Playa:</label>
                                    <input type="text"
                                           formControlName="ubiPlaya"
                                           [readOnly]="entrada.swValidado ==='2'"
                                           mask="00.00.00.00.00.00"
                                           class="form-control form-control-sm"
                                           [ngClass]="{ 'form-control-danger': enviado && f['ubiPlaya'].errors }">

                                    <div *ngIf="enviado && f['ubiPlaya'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['ubiPlaya'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['ubiPlaya'].errors['minlength']">Debe contener 12 dígitos</div>
                                        <div *ngIf="f['ubiPlaya'].errors['maxlength']">Debe contener 12 dígitos</div>
                                        <div *ngIf="entradaForm.get('ubiPlaya')?.errors?.['apiError']">{{
                                            entradaForm.get('ubiPlaya')?.errors?.['apiError'] }}</div>
                                    </div>

                                </div>
                            </div>



                            <!-- id_cliente -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['id_cliente'].errors }">
                                    <label class="form-control-label">Cliente:</label>
                                    <select formControlName="id_cliente"
                                            (change)="cambiaCliente()"
                                            class="form-control form-control-sm"
                                            [ngClass]="{ 'form-control-danger': enviado && f['id_cliente'].errors }">
                                        <option *ngFor="let cliente of clientesList"
                                                [value]="cliente.id">
                                            {{cliente.nombre}}
                                        </option>
                                    </select>
                                    <div *ngIf="enviado && f['id_cliente'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['id_cliente'].errors['required']">Obligatorio</div>
                                        <div *ngIf="entradaForm.get('id_cliente')?.errors?.['apiError']">{{
                                            entradaForm.get('id_cliente')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>




                            <!-- albaran -->
                            <div class="col-md-3">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['albaran'].errors }">
                                    <label class="form-control-label">Albarán:</label>

                                    <div class="input-group">
                                        <input type="text"
                                               formControlName="albaran"
                                               class="form-control form-control-sm"
                                               (blur)="toUpper('albaran')"
                                               maxlength="30"
                                               [ngClass]="{ 'form-control-danger': enviado && f['albaran'].errors }">
                                        <div class="input-group-append">

                                            <!-- <button *ngIf="entrada.id" -->
                                            <button (click)="abrirModal()"
                                                    class="btn btn-outline-secondary"
                                                    data-toggle="tooltip"
                                                    title="Upload PDF del Albarán"
                                                    type="button"><i class="fa-solid fa-arrow-up-from-bracket"></i></button>

                                            <button *ngIf="entrada.pdf_albaran"
                                                    (click)="abrirPDF(entrada)"
                                                    class="btn btn-outline-secondary"
                                                    data-toggle="tooltip"
                                                    title="Abrir PDF del Albarán"
                                                    type="button"><i class="fa-regular fa-file-pdf"></i></button>

                                            <button *ngIf="entrada.pdf_albaran"
                                                    (click)="borrarPDF(entrada)"
                                                    class="btn btn-outline-secondary"
                                                    data-toggle="tooltip"
                                                    title="Eliminar PDF del Albarán"
                                                    type="button"><i class="fa-solid fa-trash-can"></i></button>
                                        </div>
                                    </div>
                                    <div *ngIf="enviado && f['albaran'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['albaran'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['albaran'].errors['minlength']">Mínimo 4 caracteres</div>
                                        <div *ngIf="entradaForm.get('albaran')?.errors?.['apiError']">{{
                                            entradaForm.get('albaran')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>



                            <!-- proveedor -->
                            <div class="col-md-3">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['proveedor'].errors }">
                                    <label class="form-control-label">Proveedor:</label>
                                    <input type="text"
                                           step='1'
                                           formControlName="proveedor"
                                           class="form-control form-control-sm"
                                           [ngClass]="{ 'form-control-danger': enviado && f['proveedor'].errors }">

                                    <div *ngIf="enviado && f['proveedor'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="entradaForm.get('proveedor')?.errors?.['apiError']">
                                            {{entradaForm.get('proveedor')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div> <!-- row -->



                        <div class="row"> <!-- facturación -->


                            <!-- devolucion -->
                            <div class="col-md-4">
                                <div class="demo-checkbox">
                                    <label for="devolucion"
                                           class="form-control-label">Tipo de Entrada:</label>
                                    <div class="form-check form-check-inline reduce-margin">
                                        <input class="form-check-input"
                                               formControlName="devolucion"
                                               type="radio"
                                               name="devolucion"
                                               id="devolucion01"
                                               value="0">
                                        <label class="width100"
                                               for="devolucion01">Normal</label>

                                        <input class="form-check-input"
                                               formControlName="devolucion"
                                               type="radio"
                                               name="devolucion"
                                               id="devolucion02"
                                               value="1">
                                        <label class="width100"
                                               for="devolucion02">Por devolución</label>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-2 text-right">
                                <br>
                                <h6>Datos para la facturación de esta entrada a almacén:</h6>
                            </div>


                            <!-- contenedor -->
                            <div class="col-md-1">
                                <div *ngIf="f['devolucion'].value == '0'"
                                     class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['contenedor'].errors }">
                                    <label class="form-control-label">Contenedor:</label>
                                    <select formControlName="contenedor"
                                            class="form-control form-control-sm"
                                            [ngClass]="{ 'form-control-danger': enviado && f['contenedor'].errors }">
                                        <option value="0">Ninguno</option>
                                        <option value="20 pies">20 pies</option>
                                        <option value="40 pies">40 pies</option>
                                    </select>
                                    <div *ngIf="enviado && f['contenedor'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['contenedor'].errors['required']">Obligatorio</div>
                                        <div *ngIf="entradaForm.get('contenedor')?.errors?.['apiError']">{{
                                            entradaForm.get('contenedor')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>



                            <!-- fac_pales -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['fac_pales'].errors }">
                                    <label class="form-control-label">Palés descargados:</label>
                                    <input type="text"
                                           step='1'
                                           formControlName="fac_pales"
                                           class="form-control form-control-sm"
                                           mask="separator.0"
                                           suffix=" Palés"
                                           [ngClass]="{ 'form-control-danger': enviado && f['fac_pales'].errors }">

                                    <div *ngIf="enviado && f['fac_pales'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="entradaForm.get('fac_pales')?.errors?.['apiError']">
                                            {{entradaForm.get('fac_pales')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- fac_horas -->
                            <div class="col-md-1">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['fac_horas'].errors }">
                                    <label class="form-control-label">Horas:</label>
                                    <input type="text"
                                           step='1'
                                           formControlName="fac_horas"
                                           class="form-control form-control-sm"
                                           mask="separator.2"
                                           suffix=" horas"
                                           [ngClass]="{ 'form-control-danger': enviado && f['fac_horas'].errors }">

                                    <div *ngIf="enviado && f['fac_horas'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="entradaForm.get('fac_horas')?.errors?.['apiError']">
                                            {{entradaForm.get('fac_horas')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- fac_etiquetas -->
                            <div class="col-md-2">
                                <div *ngIf="cliente.fac_entrada_LiteralEtiqueta"
                                     class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['fac_etiquetas'].errors }">
                                    <label class="form-control-label">{{cliente.fac_entrada_LiteralEtiqueta}}:</label>
                                    <input type="text"
                                           step='1'
                                           formControlName="fac_etiquetas"
                                           class="form-control form-control-sm"
                                           mask="separator.0"
                                           suffix=" {{cliente.fac_entrada_LiteralEtiqueta}}"
                                           [ngClass]="{ 'form-control-danger': enviado && f['fac_etiquetas'].errors }">

                                    <div *ngIf="enviado && f['fac_etiquetas'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="entradaForm.get('fac_etiquetas')?.errors?.['apiError']">
                                            {{entradaForm.get('fac_etiquetas')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> <!-- row facturación -->

                    </div>

                </form>


                <div *ngIf="detallActivo"
                     class="row">


                    <!-- form de detall -->
                    <form class="form p-t-20"
                          [formGroup]="detallForm"
                          (ngSubmit)="agregarDetall()"
                          class="form-horizontal">
                        <div class="form-body">

                            <div class="col-12">
                                <div class="card border border-info rounded">
                                    <div class="card-body">
                                        <div class="row">


                                            <!-- pale -->
                                            <div class="col-md-1">
                                                <div class="form-group"
                                                     [ngClass]="{ 'has-danger': enviadoDetall && fd['pale'].errors }">
                                                    <label class="form-control-label">Palé:</label>
                                                    <input type="number"
                                                           formControlName="pale"
                                                           class="form-control"
                                                           [ngClass]="{ 'form-control-danger': enviado && fd['pale'].errors }">

                                                    <div *ngIf="enviado && fd['pale'].errors"
                                                         class="form-control-feedback">
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- id_articulo -->
                                            <div class="col-md-3">
                                                <div class="form-group"
                                                     [ngClass]="{ 'has-danger': enviadoDetall && fd['id_articulo'].errors }">
                                                    <label class="form-control-label">Sku Artículo:</label>
                                                    <div id="ngSelectContainer">
                                                        <ng-select formControlName="id_articulo"
                                                                   (ngModelChange)="cambiaArticulo()"
                                                                   class="form-control"
                                                                   [ngClass]="{ 'form-control-danger': enviadoDetall && fd['id_articulo'].errors }"
                                                                   [items]="articulosList"
                                                                   bindLabel=""
                                                                   bindValue="id"
                                                                   [searchFn]="customSearchFnArticulo"
                                                                   clearAllText="Clear">

                                                            <ng-template ng-label-tmp
                                                                         let-item="item">
                                                                <span>{{ item.ean + ' (' + item.descripcion + ')' }}</span>
                                                            </ng-template>
                                                            <ng-template ng-option-tmp
                                                                         let-item="item"
                                                                         let-search="searchTerm"
                                                                         let-index="index">
                                                                <span [ngOptionHighlight]="search">{{ item.ean + ' - ' + item.sku + ' (' + item.descripcion + ')' }}</span>
                                                            </ng-template>

                                                        </ng-select>
                                                    </div>
                                                    <div *ngIf="enviado && fd['id_articulo'].errors"
                                                         class="form-control-feedback">
                                                        <div *ngIf="fd['id_articulo'].errors['required']">Obligatorio</div>
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- cantidad -->
                                            <div class="col-md-1">
                                                <div class="form-group"
                                                     [ngClass]="{ 'has-danger': enviadoDetall && fd['cantidad'].errors }">
                                                    <div class="d-flex align-items-center">
                                                        <label class="form-control-label mr-2">Cantidad:</label>
                                                    </div>
                                                    <input formControlName="cantidad"
                                                           class="form-control"
                                                           [ngClass]="{ 'form-control-danger': enviadoDetall && fd['cantidad'].errors }">

                                                    <div *ngIf="enviadoDetall && fd['cantidad'].errors"
                                                         class="form-control-feedback">
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- lote -->
                                            <div class="col-md-3">
                                                <div class="form-group"
                                                     [ngClass]="{ 'has-danger': enviadoDetall && fd['lote'].errors }">
                                                    <label class="form-control-label">Lote:</label>
                                                    <input type="text"
                                                           [readonly]="articulo.swLote != '1'"
                                                           formControlName="lote"
                                                           (blur)="onLoteBlur()"
                                                           class="form-control"
                                                           [ngClass]="{ 'form-control-danger': enviado && fd['lote'].errors }">

                                                    <div *ngIf="enviado && fd['lote'].errors"
                                                         class="form-control-feedback">
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- fecha_caducidad -->
                                            <div class="col-md-2  ">
                                                <div class="form-group"
                                                     [ngClass]="{ 'has-danger': enviadoDetall && fd['fecha_caducidad'].errors }">
                                                    <label class="form-control-label">Fecha de caducidad:</label>
                                                    <input type="date"
                                                           [readonly]="articulo.swLote != '1' || articulo.readOnlyFecCaducidad"
                                                           formControlName="fecha_caducidad"
                                                           class="form-control"
                                                           [ngClass]="{ 'form-control-danger': enviado && fd['fecha_caducidad'].errors }">

                                                    <div *ngIf="enviado && fd['fecha_caducidad'].errors"
                                                         class="form-control-feedback">
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Agregar artículo -->
                                            <div class="col-sm-1">
                                                <div class="form-group">
                                                    <button class="btn btn-outline-primary btnAdd"
                                                            type="submit">
                                                        Agregar
                                                    </button>
                                                </div>
                                            </div>

                                            <!-- Agregar artículo defectuoso -->
                                            <div class="col-sm-1">
                                                <div *ngIf="detallForm.get('swDefectuoso')!.value == Constantes.NO_DEFECTUOSO "
                                                     class="form-group">
                                                    <button class="btn btn-outline-warning btnAdd"
                                                            type="button">
                                                        <span (click)="defectuososClick()"
                                                              title="Agregar como defectuoso"
                                                              class="pointer">
                                                            Def. <i class="ml-1 ti-alert"></i></span>
                                                    </button>
                                                </div>
                                            </div>





                                        </div> <!-- row -->


                                        <!-- row con la descripcion del artículo -->
                                        <div class="row">

                                            <div class="col-md-1">
                                            </div>

                                            <!-- descripcion -->
                                            <div class="col-md-11 descripArt">
                                                <small class="ml-2">{{ this.articulo.descripcion }}</small>
                                            </div>

                                        </div><!-- row -->





                                        <!-- Lista de artículos añadidos  -->
                                        <div class="row">

                                            <table class="table table-striped table-hover table-sm">
                                                <tbody>
                                                    <tr *ngFor="let linea of detallesArr;let i=index"
                                                        [ngClass]="{'text-warning':linea.swDefectuoso == Constantes.DEFECTUOSO}">
                                                        <td class="col-md-1 text-right">
                                                            <div class="mr-20">{{linea.pale}}</div>
                                                        </td>
                                                        <td class="col-md-3">
                                                            <div class="pl-25">
                                                                {{linea.artSku}}
                                                                <div class="descripArtDet">
                                                                    {{linea.artDescripcion}}
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="col-md-1 text-right">

                                                            <div class="pl-25 mr-20 monospace">
                                                                <span *ngIf="linea.swDefectuoso == Constantes.DEFECTUOSO">
                                                                    <i title="Defectuoso"
                                                                       class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                                                                </span>
                                                                {{linea.cantidad | number: '0.0-0':'es'}}
                                                            </div>
                                                        </td>

                                                        <td class="col-md-3">
                                                            <div class="pl-25">{{linea.lote}}</div>
                                                        </td>

                                                        <td class="col-md-2">
                                                            <span [innerHTML]="linea.fecha_caducidad | iconFechaCaducada"></span>
                                                        </td>

                                                        <td *ngIf="!linea.ubicacion"
                                                            class="col-md-2">
                                                            <button class="btn btn-outline-danger btnDel"
                                                                    type="button"
                                                                    (click)="borrarLinea(i)">
                                                                Borrar
                                                            </button>

                                                            <button class="ml-1 btn btn-outline-success btnMod"
                                                                    type="button"
                                                                    (click)="modifLinea(i)">
                                                                Modif
                                                            </button>
                                                        </td>

                                                        <td *ngIf="linea.ubicacion"
                                                            class="col-md-2">
                                                            <p class="ml-3 ubiCod monospace">{{linea.ubicacion | mask: '00.00.00.00.00.00'}}</p>
                                                        </td>



                                                    </tr>
                                                </tbody>
                                            </table>




                                        </div> <!-- row -->










                                    </div>
                                </div>
                            </div>

                        </div> <!-- row -->


                    </form>
                </div>


                <hr>

                <div class="d-flex">
                    <button [disabled]="loading"
                            type="button"
                            class="btn waves-effect waves-light btn-rounded btn-secondary"
                            [routerLink]="'/panel/entradas_lista'">Cancelar</button>

                    <button [disabled]="loading"
                            (click)="enviar()"
                            type="button"
                            class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                        <i *ngIf="loading"
                           class="fa fa-spinner fa-spin"></i>
                        {{ entrada.swValidado === '1' ? 'Validar' : 'Enviar' }}

                    </button>
                </div>

            </div>


        </div> <!-- formBody -->



        <span *ngIf="!produccion">

            <span>entradaForm.valid </span>
            <pre>{{ entradaForm.valid }}</pre>

            ['albaran'].errors
            <pre>{{f['albaran'].errors}}</pre>

            <span>entradaForm.value</span>
            <pre>{{ entradaForm.value | json }}</pre>

            <span>detallForm.value</span>
            <pre>{{ detallForm.value | json }}</pre>

            <span>Agregar Detalle</span>
            <pre>detallForm.valid: {{ detallForm.valid }}</pre>
            <pre>detallesArr: {{ detallesArr | json }}</pre>

        </span>

    </div>
</div>