<div class="row animated fadeIn fast">

    <div class="col-lg-12">
        <div class="card">

            <div class="card-body">
                <form class="form p-t-20"
                      [formGroup]="artForm"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">
                    <div class="form-body">

                        <div class="row">

                            <!-- id_cliente -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['id_cliente'].errors }">
                                    <label class="form-control-label">Cliente</label>

                                    <div *ngIf="paramId !== 'new'"
                                         class="mt-2">
                                        {{articulo.cliNombre}}
                                    </div>

                                    <span *ngIf="paramId === 'new'">
                                        <select formControlName="id_cliente"
                                                class="form-control"
                                                [ngClass]="{ 'form-control-danger': enviado && f['id_cliente'].errors }">
                                            <option *ngFor="let cliente of clientesList"
                                                    [value]="cliente.id">
                                                {{cliente.nombre}}
                                            </option>
                                        </select>
                                        <div *ngIf="enviado && f['id_cliente'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['id_cliente'].errors['required']">Obligatorio</div>
                                            <div *ngIf="artForm.get('id_cliente')?.errors?.['apiError']">{{
                                                artForm.get('id_cliente')?.errors?.['apiError'] }}</div>
                                        </div>

                                    </span>

                                </div>
                            </div>



                            <!-- ean -->
                            <div class="col-md-3">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['ean'].errors }">
                                    <label class="form-control-label">Ean:</label>
                                    <input type="text"
                                           formControlName="ean"
                                           (blur)="toUpper('ean')"
                                           (change)="existeEan()"
                                           [readonly]="paramId !== 'new'"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['ean'].errors }">

                                    <div *ngIf="enviado && f['ean'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['ean'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['ean'].errors['minlength']">Como mínimo 6 caracteres</div>
                                        <div *ngIf="f['ean'].errors['maxlength']">Como máximo 150 caracteres</div>
                                        <div *ngIf="artForm.get('ean')?.errors?.['apiError']">{{artForm.get('ean')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>


                            <!-- sku -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['sku'].errors }">
                                    <label class="form-control-label">Sku:</label>
                                    <input type="text"
                                           formControlName="sku"
                                           class="form-control"
                                           [readonly]="paramId !== 'new'"
                                           [ngClass]="{ 'form-control-danger': enviado && f['sku'].errors }">

                                    <div *ngIf="enviado && f['sku'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['sku'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['sku'].errors['minlength']">Como mínimo 6 caracteres</div>
                                        <div *ngIf="f['sku'].errors['maxlength']">Como máximo 150 caracteres</div>
                                        <div *ngIf="artForm.get('sku')?.errors?.['apiError']">{{artForm.get('sku')?.errors?.['apiError'] }}</div>
                                    </div>
                                    <small *ngIf="articulo.swDefectuoso == Const.DEFECTUOSO">
                                        <span class="text-warning text-right"> Artículo defectuoso
                                            <i title="Defectuoso"
                                               class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                                        </span>
                                    </small>
                                </div>
                            </div>


                            <!-- fecha -->
                            <!--   <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['fecha'].errors }">
                                    <label class="form-control-label">Fecha de entrada:</label>
                                    <input type="date"
                                           formControlName="fecha"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['fecha'].errors }">

                                    <div *ngIf="enviado && f['fecha'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="artForm.get('fecha')?.errors?.['apiError']">{{artForm.get('fecha')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>-->

                            <!-- Pasar a histórico button -->
                            <div class=" d-flex justify-content-end">
                                <div class="col-md-2 mt-4"
                                     *ngIf="(articulo.artStock! < 1 && paramId !== 'new')">
                                    <button [disabled]="loading"
                                            (click)="archivarClick()"
                                            type="button"
                                            class="btn waves-effect waves-light btn-rounded btn-outline-warning">
                                        <i *ngIf="loading"
                                           class="fa fa-spinner fa-spin"></i>
                                        Archivar
                                    </button>
                                </div>
                            </div>

                        </div>


                        <div class="row">

                            <div class="col-9">
                                <div class="row">

                                    <!-- descripcion -->
                                    <div class="col-md-10">
                                        <div class="form-group"
                                             [ngClass]="{ 'has-danger': enviado && f['descripcion'].errors }">
                                            <label class="form-control-label">Descripcion: </label>
                                            <input type="text"
                                                   formControlName="descripcion"
                                                   class="form-control"
                                                   [ngClass]="{ 'form-control-danger': enviado && f['descripcion'].errors }">

                                            <div *ngIf="enviado && f['descripcion'].errors"
                                                 class="form-control-feedback">
                                                <div *ngIf="f['descripcion'].errors['minlength']">Debe contener un mínimo de 4 caracteres</div>
                                                <div *ngIf="f['descripcion'].errors['maxlength']">Descripcion no puede exceder de 150 caracteres</div>
                                                <div *ngIf="artForm.get('descripcion')?.errors?.['apiError']">{{artForm.get('descripcion')?.errors?.['apiError'] }}</div>
                                            </div>
                                        </div>
                                    </div>





                                    <!-- swLote -->
                                    <div class="col-md-2">

                                        <div title="El stock de este atrículo gestonará lotes y fechas de caducidad"
                                             class="m-b-20">

                                            <div class="form-group mt-2"
                                                 [ngClass]="{ 'has-danger': enviado && f['swLote'].errors }">

                                                <div *ngIf="(articulo.artStock! > 0 )"
                                                     class="text-success">
                                                    <span *ngIf="articulo.swLote=='0'"> No gestiona lotes</span>
                                                    <span *ngIf="articulo.swLote=='1'"> Gestiona lotes</span>
                                                </div>

                                                <div *ngIf="(articulo.artStock! < 1 || paramId === 'new')"
                                                     class="demo-checkbox">
                                                    <label for="swLote"
                                                           class="form-control-label">Gestión de lotes:</label>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input"
                                                               formControlName="swLote"
                                                               type="radio"
                                                               name="swLote"
                                                               id="inlineRadio1"
                                                               value="0">
                                                        <label class="form-check-label"
                                                               for="inlineRadio1">No</label>

                                                        <input class="form-check-input"
                                                               formControlName="swLote"
                                                               type="radio"
                                                               name="swLote"
                                                               id="inlineRadio2"
                                                               value="1">
                                                        <label class="form-check-label"
                                                               for="inlineRadio2">Si</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>






                                    <!-- url -->
                                    <div class="col-md-7">
                                        <div class="form-group"
                                             [ngClass]="{ 'has-danger': enviado && f['url'].errors }">
                                            <label class="form-control-label">Web:</label>
                                            <input type="text"
                                                   formControlName="url"
                                                   class="form-control"
                                                   [ngClass]="{ 'form-control-danger': enviado && f['url'].errors }">

                                            <div *ngIf="enviado && f['url'].errors"
                                                 class="form-control-feedback">
                                                <div *ngIf="f['url'].errors['urlValid']">No parece una dirección url válida</div>
                                                <div *ngIf="artForm.get('url')?.errors?.['apiError']">{{artForm.get('url')?.errors?.['apiError'] }}</div>
                                            </div>
                                        </div>
                                    </div>


                                    <!-- artStock -->
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label class="form-control-label">Stock actual:</label>
                                            <input type="text"
                                                   class="form-control text-info"
                                                   [readonly]="true"
                                                   value="{{articulo.artStock | number: '0.0':'es'}} Unidades">
                                        </div>
                                    </div>


                                </div>


                            </div>


                            <!-- foto -->
                            <div class="col-md-3">
                                <label class="form-control-label ml-4 mt-2">Modificar foto:</label>
                                <div class="text-center shadow mt4">
                                    <img [src]="fotoDir +'/articulos/'+ articulo.foto"
                                         loading="lazy"
                                         (click)="abrirModal(articulo)"
                                         alt="Foto"
                                         class="img-thumbnail img-avatar pointer mt-1">
                                </div>
                            </div>



                        </div>

                        <div class="row">

                            <legend>Medidas</legend>

                            <!-- alto -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['alto'].errors }">
                                    <label class="form-control-label">Alto:</label>
                                    <input type="number"
                                           step='0.05'
                                           formControlName="alto"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['alto'].errors }">

                                    <div *ngIf="enviado && f['alto'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="artForm.get('alto')?.errors?.['apiError']">{{artForm.get('alto')?.errors?.['apiError'] }}</div>
                                    </div>
                                    <small>cm</small>
                                </div>
                            </div>


                            <!-- ancho -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['ancho'].errors }">
                                    <label class="form-control-label">Ancho:</label>
                                    <input type="number"
                                           step='0.05'
                                           formControlName="ancho"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['ancho'].errors }">

                                    <div *ngIf="enviado && f['ancho'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="artForm.get('ancho')?.errors?.['apiError']">
                                            {{artForm.get('ancho')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                    <small>cm</small>
                                </div>
                            </div>


                            <!-- largo -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['largo'].errors }">
                                    <label class="form-control-label">Largo:</label>
                                    <input type="number"
                                           step='0.05'
                                           formControlName="largo"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['largo'].errors }">

                                    <div *ngIf="enviado && f['largo'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="artForm.get('largo')?.errors?.['apiError']">
                                            {{artForm.get('largo')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                    <small>cm</small>
                                </div>
                            </div>


                            <!-- peso -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['peso'].errors }">
                                    <label class="form-control-label">Peso:</label>
                                    <input type="number"
                                           step='0.05'
                                           formControlName="peso"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['peso'].errors }">

                                    <div *ngIf="enviado && f['peso'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="artForm.get('peso')?.errors?.['apiError']">
                                            {{artForm.get('peso')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                    <small>Kg</small>
                                </div>
                            </div>


                            <!-- unidadesCaja -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['unidadesCaja'].errors }">
                                    <label class="form-control-label">Unidades Caja:</label>
                                    <input type="number"
                                           step='0.05'
                                           formControlName="unidadesCaja"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['unidadesCaja'].errors }">

                                    <div *ngIf="enviado && f['unidadesCaja'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="artForm.get('unidadesCaja')?.errors?.['apiError']">
                                            {{artForm.get('unidadesCaja')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- volumen -->
                            <div *ngIf="articulo.volumen"
                                 class="col-md-2">
                                <div class="form-group">
                                    <label class="form-control-label">Volumen:</label>
                                    <div class="mt-1">{{articulo.volumen}} <small>m3</small></div>
                                </div>
                            </div>


                        </div>






                        <hr>

                        <div class="d-flex">
                            <button [disabled]="loading"
                                    type="button"
                                    class="btn waves-effect waves-light btn-rounded btn-secondary"
                                    [routerLink]="'/panel/articulos_lista'"
                                    data-dismiss="modal">Cancelar</button>
                            <h6 class="card-subtitle ml-4 ">
                                <li *ngIf="articulo.created_at">
                                    Creado hace {{articulo.created_at! | friendlyDdmmyy}},
                                    <span [innerHTML]="articulo.origen! | articuloOrigen"></span>
                                </li>
                                <li *ngIf="articulo.updated_at"> Última modificación hace {{articulo.updated_at! | friendlyDdmmyy}}</li>
                            </h6>
                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Enviar</button>
                        </div>

                    </div>

                </form>
            </div>
        </div>
    </div>

</div>