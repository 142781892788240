<div class="row animated fadeIn fast">

    <div class="col-lg-12">
        <div class="card">

            <div class="card-body">
                <form class="form p-t-20"
                      [formGroup]="ubiForm"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">
                    <div class="form-body">

                        <div class="row">


                            <!-- codigo -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{'has-danger': enviado && f['codigo'].errors }">
                                    <label class="form-control-label">Código:</label>
                                    <input type="text"
                                           formControlName="codigo"
                                           (input)="cambiaUbi()"
                                           mask="00.00.00.00.00.00"
                                           class="form-control grande"
                                           style="font-family: 'Verdana', monospace; letter-spacing: 0.6px;"
                                           [ngClass]="{ 'form-control-danger': enviado && f['codigo'].errors }">

                                    <div *ngIf="enviado && f['codigo'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['codigo'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['codigo'].errors['minlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['codigo'].errors['maxlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['codigo'].errors?.['apiError']">{{ubiForm.get('codigo')?.errors?.['apiError']}}</div>
                                    </div>
                                    <small>{{codUbiDecript}}</small><br>
                                    <small class="text-secondary">{{infoAlmacenObj.nombre }}</small>
                                </div>
                            </div>


                            <!-- comentario -->
                            <div class="col-md-5">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['comentario'].errors }">
                                    <label class="form-control-label">Comentario:</label>
                                    <input type="text"
                                           formControlName="comentario"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['comentario'].errors }">

                                    <div *ngIf="enviado && f['comentario'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['comentario'].errors['minlength']">Opcional. Debe contener un mínimo de 4 caracteres</div>
                                        <div *ngIf="f['comentario'].errors['maxlength']">No puede exceder de 150 caracteres</div>
                                        <div *ngIf="f['comentario'].errors?.['apiError']">{{ubiForm.get('comentario')?.errors?.['apiError']}}</div>
                                    </div>
                                </div>
                            </div>


                            <!-- peso -->
                            <div class="col-md-1"
                                 title="Peso para la asignación de esta ubicación (999)">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['peso'].errors }">
                                    <label class="form-control-label">Peso:</label>
                                    <input type="number"
                                           formControlName="peso"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['peso'].errors }">

                                    <div *ngIf="enviado && f['peso'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['peso'].errors['minlength']">Opcional. Debe contener un mínimo de 4 caracteres</div>
                                        <div *ngIf="f['peso'].errors['maxlength']">No puede exceder de 150 caracteres</div>
                                        <div *ngIf="f['peso'].errors?.['apiError']">{{ubiForm.get('peso')?.errors?.['apiError']}}</div>
                                    </div>
                                </div>
                            </div>



                            <!-- orden_recogida -->
                            <div class="col-md-1"
                                 title="Numérico, cuanto más bajo, antes se asignará el paso por esta ubicación (9999999999)">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['orden_recogida'].errors }">
                                    <label class="form-control-label">Orden:</label>
                                    <input type="number"
                                           formControlName="orden_recogida"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['orden_recogida'].errors }">

                                    <div *ngIf="enviado && f['orden_recogida'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['orden_recogida'].errors['minlength']">Opcional. Debe contener un mínimo de 4 caracteres</div>
                                        <div *ngIf="f['orden_recogida'].errors['maxlength']">No puede exceder de 150 caracteres</div>
                                        <div *ngIf="f['orden_recogida'].errors?.['apiError']">{{ubiForm.get('orden_recogida')?.errors?.['apiError']}}</div>
                                    </div>
                                </div>
                            </div>


                            <!-- swOcupado -->
                            <div class="col-md-1"
                                 title="Esta ubicación está actualmente ocupada">
                                <div class="form-group mt-2"
                                     [ngClass]="{ 'has-danger': enviado && f['swOcupado'].errors }">
                                    <br>
                                    <div class="demo-checkbox">
                                        <input type="checkbox"
                                               id="swOcupado"
                                               formControlName="swOcupado"
                                               class="filled-in form-control"
                                               [ngClass]="{ 'form-control-danger': enviado && f['swOcupado'].errors }">
                                        <label for="swOcupado"
                                               class="form-control-label">Ocupada</label>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div *ngIf="ubicacion.descripcion"
                                 class="col-2">
                                Código:
                                <ul>
                                    <li *ngFor="let item of infoUbicacion()"><small>{{ item }}</small></li>
                                </ul>
                            </div>

                            <div *ngIf="ubicacion.tipo"
                                 class="col-6">
                                Típo:
                                <ul>
                                    <li><small><i>Código: </i> {{ ubicacion.tipo.codigo}}</small></li>
                                    <li><small><i>Nombre: </i> {{ ubicacion.tipo.nombre}}</small></li>
                                    <li><small><i>Lagro máximo: </i> <b> {{ ubicacion.tipo.largo}}</b> cm.</small></li>
                                    <li><small><i>Ancho máximo: </i> <b> {{ ubicacion.tipo.ancho}}</b> cm.</small></li>
                                    <li><small><i>Alto máximo: </i> <b> {{ ubicacion.tipo.alto}}</b> cm.</small></li>
                                    <li><small><i>Peso máximo: </i> <b> {{ ubicacion.tipo.peso}}</b> kg.</small></li>
                                </ul>

                                <a [routerLink]="['/panel/ubi_tipo',ubicacion.tipo.id]"
                                   class="link ml-2 mr-2"
                                   title="Editar el tipo de ubicación">
                                    <span class="badge badge-pill badge-success p-1">
                                        <i class="fa-regular fa-pen-to-square fa"></i> Modificar
                                    </span>
                                </a>
                            </div>
                        </div>

                        <hr>


                        <div class="d-flex">
                            <button [disabled]="loading"
                                    type="button"
                                    class="btn waves-effect waves-light btn-rounded btn-secondary"
                                    [routerLink]="'/panel/ubicaciones'"
                                    data-dismiss="modal">Cancelar</button>
                            <h6 class="card-subtitle ml-4 ">
                                <li *ngIf="ubicacion.created_at"> Creado hace {{ubicacion.created_at! | friendlyDdmmyy}}</li>
                                <li *ngIf="ubicacion.updated_at"> Última modificación hace {{ubicacion.updated_at! | friendlyDdmmyy}}</li>
                            </h6>
                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Enviar</button>
                        </div>

                    </div>

                </form>

                <!--
                <span>
                    <pre>
                        {{ubiForm.value | json }}
                    </pre>


                    ['codigo'].errors
                    <pre>{{f['codigo'].errors |json}}</pre>

                </span>
                -->


            </div>
        </div>
    </div>

</div>