<div class="row">
    <div class="col-12 animated fadeIn fast">
        <div class="card border border-info rounded">
            <div class="card-body ">

                <form (ngSubmit)="enviar()">
                    <div class="row">

                        <!-- cliSeleccionado -->
                        <div class="col-4">
                            <div class="input-group border border-dark rounded"
                                 [ngClass]="{ 'has-danger': errores.cliSeleccionado }">
                                <div class="input-group-addon">Cliente</div>
                                <select class="form-control"
                                        [(ngModel)]="cliSeleccionado"
                                        name="cliSeleccionado"
                                        (ngModelChange)="onClienteSelect($event)">
                                    <option value=''>Selecciona...</option>
                                    <option *ngFor="let cli of clientesList"
                                            [value]=cli.id>
                                        {{cli.nombre}}
                                    </option>
                                </select>
                            </div>

                            <div *ngIf="enviado && errores.cliSeleccionado"
                                 class="form-control-feedback">
                                <span class="text-danger">{{ errores.cliSeleccionado }}</span>
                            </div>
                        </div>


                        <!-- fechaDesde -->
                        <div class="col-3">
                            <div class="input-group border border-dark rounded"
                                 [ngClass]="{ 'has-danger': errores.fechaDesde }">
                                <div class="input-group-addon">Desde</div>
                                <input class="form-control"
                                       type="date"
                                       [(ngModel)]="fechaDesde"
                                       name="fechaDesde"
                                       required>
                            </div>
                            <div *ngIf="enviado && errores.fechaDesde"
                                 class="form-control-feedback">
                                <span class="text-danger">{{ errores.fechaDesde }}</span>
                            </div>
                        </div>


                        <!-- fechaHasta -->
                        <div class="col-3">
                            <div class="input-group border border-dark rounded"
                                 [ngClass]="{ 'has-danger': errores.fechaHasta }">
                                <div class="input-group-addon">Hasta</div>
                                <input class="form-control"
                                       type="date"
                                       [(ngModel)]="fechaHasta"
                                       name="fechaHasta"
                                       required>
                            </div>
                            <div *ngIf="enviado && errores.fechaHasta"
                                 class="form-control-feedback">
                                <span class="text-danger">{{ errores.fechaHasta }}</span>
                            </div>
                        </div>


                        <!-- Submit -->
                        <div class="col-2">
                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-2 btn waves-effect waves-light btn-rounded btn-success">
                                <i class="fa fa-spinner fa-spin"
                                   *ngIf="loading"></i> Enviar</button>
                        </div>


                    </div><!-- row -->
                </form>

            </div>
        </div>
    </div>
</div> <!-- row -->

<div class="row">

    <!-- Entradas -->
    <div class="col-6 animated fadeIn fast">
        <app-facturacion-entradas [datosCabecera]="datosCabecera"
                                  (totalEntradas)="handleTotalEntradas($event)"></app-facturacion-entradas>
    </div>

    <!-- Devoluciones -->
    <div class="col-6 animated fadeIn fast">
        <app-facturacion-devoluciones [datosCabecera]="datosCabecera"
                                      (totalDevoluciones)="handleTotalDevoluciones($event)"></app-facturacion-devoluciones>
    </div>

    <!-- Salidas -->
    <div class="col-6 animated fadeIn fast">
        <app-facturacion-salidas [datosCabecera]="datosCabecera"
                                 (totalSalidas)="handleTotalSalidas($event)"></app-facturacion-salidas>
    </div>

    <!-- Almacenaje -->
    <div class="col-6 animated fadeIn fast  ">
        <app-facturacion-almacenaje [datosCabecera]="datosCabecera"
                                    (totalAlmacenaje)="handleTotalAlmacenaje($event)"></app-facturacion-almacenaje>
    </div>

    <!-- Seguro -->
    <div class="col-6 animated fadeIn fast  ">
        <app-facturacion-seguros [datosCabecera]="datosCabecera"
                                 (totalSeguros)="handleTotalSeguros($event)"></app-facturacion-seguros>
    </div>



    <!-- Totales -->
    <div class="col-6 animated fadeIn fast  ">

        <span *ngIf="granTotal > 0">
            <div class="card border border-info rounded border-grueso">
                <div class="card-body">

                    <div class="d-flex justify-content-between align-items-center">

                        <h2>TOTALES</h2>

                        <button class="btn waves-effect waves-light btn-primary mb-2"
                                [disabled]="loading"
                                (click)="confirmaClick()">
                            <i class="fa fa-spinner fa-spin"
                               *ngIf="loading"></i> Confirmar
                        </button>

                        <!-- Xlsx -->
                        <button [disabled]="loading"
                                class="btn waves-effect waves-light btn-success mb-2"
                                type="button"
                                (click)="facCrearXls()">
                            <i class="fa fa-spinner fa-spin"
                               *ngIf="loading"></i> Generar Xlsx
                        </button>

                        <span *ngIf="linkXls == ''"
                              style="width: 2em;"></span>
                        <a href="{{ linkXls }}"
                           *ngIf="linkXls != ''"
                           title="Descargar Xlsx"
                           class="text-success">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                        </a>

                        <!-- Pdf -->
                        <button class="btn waves-effect waves-light btn-seccondary mb-2"
                                [disabled]="loading"
                                (click)="generarPdf()">
                            <i class="fa fa-spinner fa-spin"
                               *ngIf="loading"></i> Generar resumen Pdf
                        </button>

                        <span *ngIf="linkPdf == ''"
                              style="width: 2em;"></span>
                        <a href="{{ linkPdf }}"
                           *ngIf="linkPdf != ''"
                           title="Descargar Pdf"
                           class="text-secondary"
                           target="_blank">
                            <i class="fa fa-file-pdf-o fa-2x"></i>
                        </a>


                    </div>

                    <table class="table table-sm table-striped table-bordered table-hover">

                        <thead>
                            <tr>
                                <th class="text-info"
                                    style="text-align: right;">Totales en el periodo </th>
                                <th style="text-align: right;">Importes </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="text-align: right;">Entradas</td>
                                <td style="text-align: right;">{{ totalEntradas | euroCurrency }} </td>
                            </tr>
                            <tr>
                                <td style="text-align: right;">Devoluciones</td>
                                <td style="text-align: right;">{{ totalDevoluciones | euroCurrency }} </td>
                            </tr>
                            <tr>
                                <td style="text-align: right;">Salidas</td>
                                <td style="text-align: right;">{{ totalSalidas | euroCurrency }} </td>
                            </tr>
                            <tr>
                                <td style="text-align: right;">Almacenaje</td>
                                <td style="text-align: right;">{{ totalAlmacenaje | euroCurrency }} </td>
                            </tr>
                            <tr>
                                <td style="text-align: right;">Seguros</td>
                                <td style="text-align: right;">{{ totalSeguros | euroCurrency }} </td>
                            </tr>
                        </tbody>

                        <tfoot>
                            <tr class="total-row">
                                <td style="text-align: right;"><strong> Gran total</strong></td>
                                <td style="text-align: right;"><strong>{{ granTotal | euroCurrency }}</strong></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </span>


    </div>

</div>



<!-- 
<span>enviado</span>
<pre>{{ enviado | json }}</pre>
<span>errores</span>
<pre>{{ errores | json }}</pre>
<span>errores.length</span>
<pre>{{ getErroresLength() }}</pre>
<span>datosFacturacion</span>
<pre>{{ datosFacturacion | json }}</pre> 
-->