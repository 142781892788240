import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Servicios
import { NotificacionesService } from '../notificaciones.service';
import { UsuarioService } from './usuario.service';

// Interfaces / Modelos
import { IApiResp } from 'src/app/interfaces/api-resp';
import { IArticulo } from 'src/app/interfaces/articulo';

// Librerías
import { environment } from 'src/environments/environment';
import { catchError, throwError } from 'rxjs';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';

@Injectable({
  providedIn: 'root'
})
export class ArticulosService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }

  getLista() {
    // Devulve la lista  
    const path = `${this.apiURL}/articulos/list`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }


  getListaFiltrada(_opciones: IListaOpciones = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/articulos/listFiltro`;

    const data = {
      id_almacen: _opciones.id_almacen || null,
      id_cliente: _opciones.id_cliente || null,
      id_articulo: _opciones.id_articulo || null,
      id_ubicacion: _opciones.id_ubicacion || null
    };

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  getArticulo(id: number) {
    // Devulve 1 registro
    const path = `${this.apiURL}/articulos/list/${id}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }

  getArtAgrupadosPorCampanya(id: number) {
    // Devulve artículos de una campaña
    const path = `${this.apiURL}/articulos/ArtAgrupadosPorCampanya/${id}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }

  newRec(formData: any) {
    const path = `${this.apiURL}/articulos/new`;
    return this.http.post<IApiResp>(path, formData)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      );
  }

  updateRec(articulo: IArticulo) {
    const path = `${this.apiURL}/articulos/update`;
    return this.http.post<IApiResp>(path, articulo)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  delete(id: number) {
    const path = `${this.apiURL}/articulos/delete/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  creaDefectuoso(id: number) {
    const path = `${this.apiURL}/articulos/creaDefectuoso/${id}`;
    return this.http.get<IApiResp>(path)
  }



  archivar(id: number) {
    // Mueve un registro a articulos_historico
    const path = `${this.apiURL}/articulos/archivar/${id}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }


  getHistEan(data: any) {
    // Devulve 1 registro del historico
    const path = `${this.apiURL}/articulos/getHistEan`;
    return this.http.post<IApiResp>(path, data)

  }

  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }


}
