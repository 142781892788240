import { AvisosService } from './../../../services/datos/avisos.service';
import * as Constantes from '../../../shared/constants';

import { EntradasService } from 'src/app/services/datos/entradas.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// Interfaces / Modelos
import { IEntrada, IEntradaDetall } from 'src/app/interfaces/entrada';
import { ICliente } from 'src/app/interfaces/cliente';
import { IArticulo } from 'src/app/interfaces/articulo';
import { IAlmacen } from 'src/app/interfaces/almacenes';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';
import { IEmail } from 'src/app/interfaces/email';

// Servicios
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { ArticulosService } from 'src/app/services/datos/articulos.service';
import { StockService } from 'src/app/services/datos/stock.service';
import { AlmacenesService } from 'src/app/services/datos/almacenes.service';
import { TransaccionesService } from 'src/app/services/datos/transacciones.service';
import { ModalPdfService } from 'src/app/services/modal-pdf.service';
import { EmailService } from 'src/app/services/mail.service';

// librerías
import CustomVal from 'src/app/providers/CustomValidators';
import { environment } from 'src/environments/environment';
import { Subscription, delay, pipe } from 'rxjs';
import Swal from 'sweetalert2';
import { format, parse } from 'date-fns';



@Component({
  selector: 'app-entrada-edit',
  templateUrl: './entrada-edit.component.html',
  styleUrls: ['./entrada-edit.component.scss']
})
export class EntradaEditComponent implements OnInit {

  // Usar cantantes en el template
  Constantes = Constantes

  produccion: boolean = environment.production;

  public articulo: IArticulo = {}

  public pdfDir: string = environment.pdfDir;
  public pdfSubs: Subscription | undefined;

  almSeleccionado: string = this.usuarioSrv.userdata.alm_por_defecto || '0';    // Almacén por defecto
  cliSeleccionado: number | null = null;
  public cliente: ICliente = {};  // Datos del cliente seleccionado

  public almNombre: string = '';    // Nombre del almacén

  clientesList: ICliente[] = [];
  almacenesList: IAlmacen[] = [];
  articulosList: IArticulo[] = [];

  entrada: IEntrada = {};
  detallesArr: IEntradaDetall[] = [];

  public entradaForm: FormGroup = new FormGroup({});
  public detallForm: FormGroup = new FormGroup({});

  public enviado: boolean = false;
  public enviadoDetall: boolean = false;

  public detallActivo: boolean = false;
  public defectuosos: boolean = false;

  public loading: boolean = false;
  environment: any;
  paramId: string = '';

  opcionesList: IListaOpciones = {}

  selectedFileName: string | null = null;   // Nombre del file uploaded con el albarán


  constructor(
    private transacSrv: TransaccionesService,
    private entradaSrv: EntradasService,
    public clientesSrv: ClientesService,
    public almacenSrv: AlmacenesService,
    public articulosSrv: ArticulosService,
    public stockSrv: StockService,
    public usuarioSrv: UsuarioService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private notificacionesSrv: NotificacionesService,
    private router: Router,
    private modalPdfSrv: ModalPdfService,
    private avisosSrv: AvisosService,
    private eMailSrv: EmailService
  ) {

    // Definir formularios

    this.entradaForm = this.fb.group({
      id_cliente: ['', Validators.required],
      id_almacen: [this.almSeleccionado, Validators.required],
      ubiPlaya: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]],
      albaran: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(150)]],
      pdf_albaran: [''],
      proveedor: [''],
      devolucion: ['0'],
      contenedor: ['0'],
      fac_contenedor20: ['0'],
      fac_contenedor40: ['0'],
      fac_pales: [''],
      fac_horas: [''],
      fac_literal_etiquetas: [''],
      fac_etiquetas: [''],
      //  fecha_entrada: [new Date().toISOString().substr(0, 10), Validators.required],
    });

    this.detallForm = this.fb.group({
      pale: [1],
      id_articulo: [null, Validators.required],
      cantidad: ['', [CustomVal.esEntero, Validators.required]],
      swDefectuoso: [Constantes.NO_DEFECTUOSO],
      lote: [''],
      fecha_caducidad: [null],
    })
  }

  ngOnInit(): void {
    this.listaCli();
    this.listaAlamcenes();

    // Suscribirse a los parámetros de la url, si envían una id, cargar los datos de la entrada
    this.activatedRoute.params.subscribe(({ id }) => {
      this.paramId = id;
      if (this.paramId != 'new') this.cargaEntrada(id);
    })


    // Nos suscribimos al albaranPdf, 
    // Si cambia, buscamos esa id en this.lista[]

    this.pdfSubs = this.modalPdfSrv.nuevoPdf
      .pipe(delay(100))
      .subscribe(resp => {
        this.entrada.pdf_albaran = resp.guardado_como
        this.entradaForm.patchValue({
          pdf_albaran: resp.guardado_como
        })

        console.log('__pdfSubs, next recibido en la subscripción', resp)

      });
  }

  cargaEntrada(id: number) {
    this.loading = true;
    this.entradaSrv.getEntrada(id)
      .subscribe(resp => {
        this.loading = false;
        console.log('__cargaEntrada()', resp);
        this.entrada = resp.data;

        // cargar los datos en entradaForm
        this.entradaForm.patchValue({
          id_cliente: resp.data.id_cliente,
          id_almacen: resp.data.id_almacen,
          ubiPlaya: resp.data.ubiPlaya,
          albaran: resp.data.albaran,
          pdf_albaran: resp.data.pdf_albaran,
          proveedor: resp.data.proveedor,
          devolucion: resp.data.devolucion,
          contenedor: resp.data.contenedor,
          fac_contenedor20: resp.data.fac_contenedor20,
          fac_contenedor40: resp.data.fac_contenedor40,
          fac_pales: resp.data.fac_pales,
          fac_horas: resp.data.fac_horas,
          fac_literal_etiquetas: resp.data.fac_literal_etiquetas,
          fac_etiquetas: resp.data.fac_etiquetas,
          //    fecha_entrada: resp.data.fecha_entrada
        });

        this.almNombre = resp.data.almNombre;

        // actualizar palé por defecto en el formDetall
        this.detallForm.patchValue({
          pale: Number(resp.data.pales) + 1
        });

        // cargar los datos en detallesArr[]
        this.detallesArr = resp.data.detall;

        // Bloquea el select de cliente y muestra detallesArr
        this.entradaForm.controls['id_cliente'].disable();
        this.cambiaCliente()

      })
  }


  enviar() {
    this.enviado = true;

    if (this.entradaForm.invalid) {
      console.log("ERRORES:", this.entradaForm);
      return;
    }

    if (!this.detallesArr.length) {
      this.notificacionesSrv.aviso('warning', `Es necesario agregar una línea de artículo como mínimo `);
      return;
    }

    // actualizar fac_contenedor20 y fac_contenedor40 en función del valor contenedor
    const contenedor = this.entradaForm.value.contenedor;
    this.entradaForm.patchValue({
      fac_contenedor20: contenedor == '20 pies' ? 1 : 0,
      fac_contenedor40: contenedor == '40 pies' ? 1 : 0,
    });


    // Todo ok, enviar al BackEnd
    let action = 'new';

    if (this.paramId != 'new') {
      // Es una validación ó modificaión

      action = 'edit';

      const id: number = parseInt(this.paramId);

      if (this.entrada.swValidado === '2') {
        // Es una modificación, Crear una transacción negativa
        this.creaTransaccion(id, 0)
      }

    }

    let origen = this.entrada.origen
    if (action === 'new') origen = '2'

    const alabran = this.entradaForm.get('albaran')?.value

    //Guardar/Crear la entrada
    let datos: any = {
      ...this.entradaForm.value,
      id_cliente: this.cliSeleccionado,
      pales: this.getPaleArr().length,
      username: this.usuarioSrv.userdata.username,
      detall: this.detallesArr,
      action: action,
      swValidado: '2',
      origen: origen
    }

    if (action === 'edit') datos.id = this.entrada.id;


    console.log('__enviar()', datos)


    this.loading = true;
    this.entradaSrv.new(datos)
      .subscribe(resp => {
        this.loading = false;

        console.log('__this.entradaSrv.new(datos)', resp)

        if (resp.error) {
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.entradaForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          if (this.paramId === 'new') this.notificacionesSrv.aviso('success', `Entrada ${alabran} creada correctamente`);
          if (this.paramId !== 'new') this.notificacionesSrv.aviso('success', `Entrada ${alabran} modificada correctamente.`);


          // Crea transacción positiva
          this.creaTransaccion(resp.data.id_entrada, 1)

          // Emnviar eMail al cliente, informando de la validación de la entrada
          const dataCli: IEmail = {
            'toEmail': this.usuarioSrv.userdata.email!,
            'subject': `La entrada con ALBARÁN ${alabran} ha sido validada `,
            'body': `La entrada con ALBARÁN ${alabran} ha sido validada<br> Ya puedes consultarla accediendo a ${environment.url}`
          }

          this.loading = true;
          this.eMailSrv.enviaEmail(dataCli).subscribe(resp => {
            this.loading = false;
            console.log('enviaEmail()', resp);

            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
              return
            }

          })


          // Crear aviso al cliente, informando de la validación de la entrada
          const data = {
            id_cliente: this.cliSeleccionado,
            tipo: '3',
            buscar_pedido: alabran,
            mensaje: `La entrada con ALBARÁN ${alabran} ha sido validada`
          }
          this.loading = true
          this.avisosSrv.avisoNew(data).subscribe(resp => {
            this.loading = false
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje)
              return
            }
            console.log(resp)
          })


          // Volver a la lista de entradas
          this.router.navigateByUrl(`panel/entradas_lista`);
        }
      })
  }



  creaTransaccion(id_entrada: number, tipo: number) {
    const datos: any = {
      id_entrada: id_entrada,
      tipo: tipo,
      username: this.usuarioSrv.userdata.username
    }

    this.loading = true;
    this.transacSrv.TransaccionEntrada(datos).subscribe(resp => {
      this.loading = false;
      console.log('__TransaccionEntrada(datos)', datos)
      console.log('__TransaccionEntrada(resp)', resp)
      if (resp.error) {
        this.notificacionesSrv.aviso('error', resp.mensaje)
        return
      }
    })
  }


  /// Detall  ////


  agregarDetall() {
    this.enviadoDetall = true;
    if (this.detallForm.invalid) {
      console.log("ERRORES:", this.detallForm);
      return;
    }

    // Cambiar '' a null
    if (this.detallForm.value.fecha_caducidad === '') {
      this.detallForm.patchValue({ fecha_caducidad: null });
    }

    // Validar fechaCaducidad contra las del Stock
    const id_articulo = this.detallForm.controls['id_articulo'].value;
    const lote = this.detallForm.controls['lote'].value;
    const formFechaCaducidad = this.detallForm.controls['fecha_caducidad'].value; // formato 'dd/MM/yyyy'

    if (lote) {
      const data = {
        "id_articulo": id_articulo,
        "lote": lote
      }
      this.loading = true;
      this.stockSrv.stockLote(data).subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.error);
          return;
        }

        if (resp.data && resp.data.length > 0 && resp.data[0].fechaCaducidad) {
          // Convertir fechas a un formato común
          const stockFechaCaducidad = resp.data[0].fechaCaducidad; // formato ISO 'yyyy-MM-dd'

          console.log('__stockFechaCaducidad', stockFechaCaducidad);
          console.log('__formFechaCaducidad', formFechaCaducidad);

          if (stockFechaCaducidad !== formFechaCaducidad) {
            // las fechas de caducidad del stock y la entrada no coinciden, preguntar cuál es la válida
            Swal.fire({
              title: 'Incoherencia en la fecha de caducidad',
              icon: 'question',
              html: `Para este artículo con lote <b>${lote}</b>, su fecha de caducidad debería ser <b>${stockFechaCaducidad}</b><br><br>¿Quieres modificar las fechas de los artículos en Stock con la nueva fecha?`,
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Sí',
            }).then((result) => {
              if (!result.isConfirmed) {
                // No quiere actualizar Stock, detener el proceso
                return;
              }

              // Actualizar Stock con la nueva fecha
              const data = {
                "id_articulo": id_articulo,
                "lote": lote,
                "fechaCaducidad": formFechaCaducidad
              }
              this.loading = true;
              this.stockSrv.UpdateFechaCaducidad(data)
                .subscribe(resp => {
                  this.loading = false;
                  if (resp.error) {
                    this.notificacionesSrv.aviso('error', resp.error)
                    return
                  }

                  this.notificacionesSrv.aviso('success', resp.mensaje);
                });

              // Continuar con el proceso de agregar detalle
              this.procesarDetalle();
            });

            return; // Detener ejecución aquí hasta que se resuelva Swal.fire
          }
        }

        // No hay incoherencias de fecha o no hay datos, continuar con el proceso de agregar detalle
        this.procesarDetalle();
      });
    } else {
      // Si no hay lote, continuar con el proceso de agregar detalle
      this.procesarDetalle();
    }
  }


  procesarDetalle() {
    const nuevoDetalle = {
      ...this.detallForm.value,
      artDescripcion: this.articulo.descripcion,
      artSku: this.articulo.sku,
    };

    // Mirar si ya existe
    const index = this.detallesArr.findIndex(detalle =>
      detalle.pale === nuevoDetalle.pale &&
      detalle.id_articulo === nuevoDetalle.id_articulo &&
      detalle.lote === nuevoDetalle.lote &&
      detalle.fecha_caducidad === nuevoDetalle.fecha_caducidad
    );

    if (index === -1) {
      // No existe un detalle con los mismos valores, agregar uno nuevo
      this.detallesArr.push(nuevoDetalle);
      this.msgExisteEnOtroPale();
    } else {
      // Ya existe un detalle con los mismos valores, actualizar las cantidades
      this.notificacionesSrv.aviso('warning', `Ya existe una entrada para este artículo en el mismo palé, se han agrupado las cantidades`);
      this.detallesArr[index].cantidad = Number(this.detallesArr[index].cantidad);
      this.detallesArr[index].cantidad! += Number(nuevoDetalle.cantidad);
    }

    const paleActual = this.detallForm.get('pale')?.value;
    this.defectuosos = false;
    this.detallForm.reset();
    this.detallForm.patchValue({ pale: paleActual });

    this.enviadoDetall = false;
    this.entradaForm.controls['id_cliente'].disable(); // Como mínimo hay una línea de detalle, Ya no se puede cambiar de cliente
  }






  msgExisteEnOtroPale(): void {
    // Mirar si el artículo en detallForm ya existe en otro palé

    const bbwk = {
      ...this.detallForm.value,
      artDescripcion: this.articulo.descripcion,
      artSku: this.articulo.sku,
    };
    const index = this.detallesArr.findIndex(detalle =>
      detalle.pale !== bbwk.pale &&
      detalle.id_articulo === bbwk.id_articulo &&
      detalle.lote === bbwk.lote &&
      detalle.fecha_caducidad === bbwk.fecha_caducidad
    );

    if (index != -1) this.notificacionesSrv.aviso('info', `Ya existe una entrada para este artículo en otro palé`);
  }

  borrarLinea(i: number) {
    this.detallesArr.splice(i, 1);
    if (!this.detallesArr.length) {
      this.entradaForm.controls['id_cliente'].enable(); // Se puede cambiar de cliente
    }
  }

  modifLinea(i: number) {
    this.detallForm.patchValue({
      pale: this.detallesArr[i].pale,
      id_articulo: this.detallesArr[i].id_articulo,
      cantidad: this.detallesArr[i].cantidad,
      swDefectuoso: this.detallesArr[i].swDefectuoso,
      lote: this.detallesArr[i].lote,
      fecha_caducidad: this.detallesArr[i].fecha_caducidad
    });
    // Mostrar icono de swDefectuoso?
    if (this.detallesArr[i].swDefectuoso == Constantes.DEFECTUOSO) this.defectuosos = true;
    this.borrarLinea(i);
  }




  cambiaArticulo() {
    const id = this.detallForm.get('id_articulo')?.value || 0;
    this.articulo = this.articulosList.find((a: IArticulo) => a.id === id) || {};

    this.detallForm.patchValue({
      descripcion: this.articulo.descripcion,
      fecha_caducidad: null,
      lote: null,
      swDefectuoso: this.articulo.swDefectuoso,
    });

    // Actualizar las validaciones de lote y fecha_caducidad
    const loteCtrl = this.detallForm.get('lote');
    const fechaCtrl = this.detallForm.get('fecha_caducidad');
    if (this.articulo.swLote == '1') {
      loteCtrl?.setValidators([Validators.required]);
      fechaCtrl?.setValidators([Validators.required]);
    } else {
      loteCtrl?.clearValidators();
      fechaCtrl?.clearValidators();
    }

    loteCtrl?.updateValueAndValidity();
    fechaCtrl?.updateValueAndValidity();
  }



  cambiaAlmacen() {
    this.almSeleccionado = this.entradaForm.controls['id_almacen'].value;

    const elemento = this.almacenesList.find(item => item.id?.toString() === this.almSeleccionado);
    const ubiPlaya = elemento ? elemento.ubiPlaya : '';

    this.entradaForm.patchValue({ ubiPlaya: ubiPlaya });
  }

  cambiaCliente() {
    this.cliSeleccionado = this.entradaForm.controls['id_cliente'].value;
    this.listaArticulos();

    // Buscar cliente seleccionado
    this.loading = true;
    this.clientesSrv.getCliente(this.cliSeleccionado!)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.error)
          return
        }
        this.cliente = resp.data
      })


    this.detallActivo = true;
  }


  // Salida del campo lote
  onLoteBlur() {
    this.toUpperDetallForm('lote');
    this.buscaFechaCaducidad();
  }


  // Si existe este artículo con este lote en Stock, actualizar fecha de caducidad
  buscaFechaCaducidad() {
    const id_articulo = this.detallForm.controls['id_articulo'].value
    const lote = this.detallForm.controls['lote'].value

    if (lote) {

      // Verificar si existe un registro con el mismo lote y id_articulo en detallesArr[]
      const existeRegistro = this.detallesArr.some(detalle => detalle.id_articulo === id_articulo && detalle.lote === lote);
      const fechaCaducidadControl = this.detallForm.get('fecha_caducidad');

      // Habilitar o deshabilitar el campo fecha_caducidad según exista o no el registro 
      if (existeRegistro) {
        this.articulo.readOnlyFecCaducidad = true
      } else {
        this.articulo.readOnlyFecCaducidad = false
      }

      const data = {
        "id_articulo": id_articulo,
        "lote": lote
      }
      this.loading = true;
      this.stockSrv.stockLote(data)
        .subscribe(resp => {
          this.loading = false;
          console.log('__stockLote() resp.data[0]', resp.data[0]);   // El primer registro

          if (resp.error) {
            this.notificacionesSrv.aviso('error', resp.error)
            return
          }

          if (resp.data && resp.data.length > 0 && resp.data[0].fechaCaducidad) {
            this.detallForm.patchValue({ fecha_caducidad: resp.data[0].fechaCaducidad });
          } else {
            this.detallForm.patchValue({ fecha_caducidad: null });
          }

        });

    }
  }



  ///   AUX   /// 


  get f() {
    return this.entradaForm.controls;
  }
  get fd() {
    return this.detallForm.controls;
  }


  toUpper(fieldName: string) {
    const formControl = this.entradaForm.get(fieldName);
    if (formControl && formControl.value) {
      formControl.setValue(formControl.value.toUpperCase());
    }
  }

  toUpperDetallForm(fieldName: string) {
    const formControl = this.detallForm.get(fieldName);
    if (formControl && formControl.value) {
      formControl.setValue(formControl.value.toUpperCase());
    }
  }


  listaCli() {
    this.clientesSrv.getLista()
      .subscribe(resp => {
        this.clientesList = resp.data;
      })
  }

  listaAlamcenes() {
    this.almacenSrv.getLista()
      .subscribe(resp => {
        this.almacenesList = resp.data;
        this.cambiaAlmacen();
      })
  }

  listaArticulos() {
    this.opcionesList.id_cliente = this.cliSeleccionado!;
    this.loading = true;
    this.articulosSrv.getListaFiltrada(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;
        this.articulosList = resp.data;
      })
  }


  getPaleArr() {
    let paleArr = [];

    paleArr = this.detallesArr
      .map(detalle => detalle.pale)
      .filter((value, index, self) => self.indexOf(value) === index);

    return paleArr;
  }


  defectuososClick() {
    // Comprobar si existe el artículo id_articulo pero con sku-def
    // Si no existe crearlo
    this.defectuosos = true;
    this.detallForm.patchValue({
      swDefectuoso: Constantes.DEFECTUOSO,
    });
    const id_articulo = this.detallForm.get('id_articulo')?.value;
    console.log('__defectuososClick()', this.detallForm.value);

    this.loading = true
    this.articulosSrv.creaDefectuoso(id_articulo).subscribe(resp => {
      this.loading = false
      console.log(`__creaDefectuoso(${id_articulo})`, resp);
      if (resp.error) {
        this.notificacionesSrv.aviso('error', resp.error)
        return
      }

      // Añadir artículo defectuoso al array de artículos  
      this.articulosList.push(resp.data)

      // hacer un patchValue del id_articulo, por el que acabamos de crear
      this.detallForm.patchValue({
        swDefectuoso: Constantes.DEFECTUOSO,
        id_articulo: resp.data.id,
        descripcion: resp.data.descripcion
      });

      // hacer submit del form
      this.agregarDetall();
    });

  }


  // customSearchFnArticulo(term: string, item: any) {
  //   term = term.toLocaleLowerCase();
  //   return item.ean.toLocaleLowerCase().indexOf(term) > -1 ||
  //     item.sku.toLocaleLowerCase().indexOf(term) > -1 ||
  //     item.descripcion.toLocaleLowerCase().indexOf(term) > -1;
  // }


  customSearchFnArticulo(term: string, item: any) {
    // Verifica que term no sea null o undefined
    if (!term) {
      return false;
    }

    term = term.toLocaleLowerCase();

    // Verifica que los campos de item no sean null o undefined
    const ean = item?.ean ? item.ean.toLocaleLowerCase() : '';
    const sku = item?.sku ? item.sku.toLocaleLowerCase() : '';
    const descripcion = item?.descripcion ? item.descripcion.toLocaleLowerCase() : '';

    // Compara term con los campos de item
    return ean.indexOf(term) > -1 || sku.indexOf(term) > -1 || descripcion.indexOf(term) > -1;
  }



  // onFileSelected(event: any): void {
  //   const inputElement = event.target as HTMLInputElement;
  //   const fileName = inputElement.files?.[0]?.name || null;
  //   this.selectedFileName = fileName;
  // }



  abrirModal() {
    // Abre modal de pdf para el albarán
    console.log('__abrirModal() this.entrada.id=', this.entrada.id)
    this.modalPdfSrv.abrirModal('albaranEntrada', (this.entrada.id || 0).toString(), this.entrada.pdf_albaran || '');
  }

  abrirPDF(entrada: IEntrada) {
    const url = this.pdfDir + '/albaranEntrada/' + entrada.pdf_albaran
    window.open(url, '_blank');
  }

  borrarPDF(entrada: IEntrada) {
    Swal.fire({
      title: 'Desvincular pdf',
      icon: 'question',
      html: `¿Quieres desvincular este pdf como albarán para esta entrada?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.entrada.pdf_albaran = '';
        this.entradaForm.patchValue({
          pdf_albaran: ''
        })
        this.notificacionesSrv.aviso('success', `Pdf desvinculado de esta entrada`)
      }
    });

  }

}
